import {useApi} from "../provider/ApiProvider";
import {useTranslation} from "react-i18next";
import {Button, FormControl, FormControlLabel, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {Equipment} from "../model/data/Equipment";
import EditComponent from "../ui/component/EditComponent";
import Checkbox from '@mui/material/Checkbox';

export default function EquipmentEditPage() {
    const {t} = useTranslation();
    const {equipmentApi} = useApi();
    const [equipment, setEquipment] = useState<Equipment | null>(null);
    const [name, setName] = useState('');


    const [canopy, setCanopy] = useState<string>('');
    const [canopySerial, setCanopySerial] = useState<string>('');
    const [reserve, setReserve] = useState<string>('');
    const [reserveSerial, setReserveSerial] = useState<string>('');
    const [aad, setAad] = useState<string>('');
    const [aadSerial, setAadSerial] = useState<string>('');
    const [container, setContainer] = useState<string>('');
    const [containerSerial, setContainerSerial] = useState<string>('');
    const [active, setActive] = useState(true);
    const [lastRepack, setLastRepack] = useState<number|null>(null);

    useEffect(() => {
        if (equipment) {
            setName(equipment.name);

            setCanopy(equipment.canopy || '');
            setCanopySerial(equipment.canopySerial || '');
            setReserve(equipment.reserve || '');
            setReserveSerial(equipment.reserveSerial || '');
            setAad(equipment.aad || '');
            setAadSerial(equipment.aadSerial || '');
            setContainer(equipment.container || '');
            setContainerSerial(equipment.containerSerial || '');
            setActive(equipment.active);
            setLastRepack(equipment.lastRepack);

        }
    }, [equipment]);

    function getEquipment(): Equipment {
        let result = equipment || new Equipment();
        result.name = name;
        result.canopy = canopy || null;
        result.canopySerial = canopySerial || null;
        result.reserve = reserve || null;
        result.reserveSerial = reserveSerial || null;
        result.aad = aad || null;
        result.aadSerial = aadSerial || null;
        result.container = container || null;
        result.containerSerial = containerSerial || null;
        result.active = active;
        result.lastRepack = lastRepack;
        return result;
    }

    return (<EditComponent api={equipmentApi} setSource={setEquipment} getSavable={getEquipment} successSavePage='/equipments'>
                    <FormControl margin='normal' fullWidth>
                        <TextField variant="standard" required id="name" label={t('equipment.name')}
                                   value={name} onChange={e => setName(e.target.value)}/>
                        <TextField variant="standard" id="canopy" label={t('equipment.canopy')}
                                   value={canopy} onChange={e => setCanopy (e.target.value)}/>
                        <TextField variant="standard" id="canopySerial" label={t('equipment.canopySerial')}
                                   value={canopySerial} onChange={e => setCanopySerial (e.target.value)}/>
                        <TextField variant="standard" id="reserve" label={t('equipment.reserve')}
                                   value={reserve} onChange={e => setReserve (e.target.value)}/>
                        <TextField variant="standard" id="reserveSerial" label={t('equipment.reserveSerial')}
                                   value={reserveSerial} onChange={e => setReserveSerial (e.target.value)}/>
                        <TextField variant="standard" id="aad" label={t('equipment.aad')}
                                   value={aad} onChange={e => setAad (e.target.value)}/>
                        <TextField variant="standard" id="aadSerial" label={t('equipment.aadSerial')}
                                   value={aadSerial} onChange={e => setAadSerial (e.target.value)}/>
                        <TextField variant="standard" id="container" label={t('equipment.container')}
                                   value={container} onChange={e => setContainer (e.target.value)}/>
                        <TextField variant="standard" id="containerSerial" label={t('equipment.containerSerial')}
                                   value={containerSerial} onChange={e => setContainerSerial (e.target.value)}/>
                        <FormControlLabel control={<Checkbox checked={active}
                                                             onChange={e => setActive(e.target.checked)} />}
                                          label={t('equipment.active')} />

                    </FormControl>
                    <FormControl margin='normal'>
                        <Button type='submit' variant='contained'>
                            {equipment?.id == null ? t('form.create'):t('form.update')}
                        </Button>
                    </FormControl>
    </EditComponent>);
}
