import {useApi} from "../provider/ApiProvider";
import {GridColDef} from '@mui/x-data-grid';
import DataTable from "../ui/component/DataTable";
import {useTranslation} from "react-i18next";


export default function TagsPage() {
    const {t} = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'label',
            headerName: t('tags.label'),
            type: 'string',
            flex: 1,
            minWidth: 150,
            editable: false,
        },
    ];

    const {jumpTagApi} = useApi();

    return (<DataTable api={jumpTagApi} deletable={true} editLocation={'/tag/'} columns={columns} />);
}
