import * as React from 'react';
import {useEffect, useState} from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import {zhCN, enUS, nlNL, Localization} from '@mui/material/locale';

export type DialogContextType = { openDialog: ((message: string, success: (() => void)) => void)};

const ThemeContext = React.createContext<{ theme: string; toggleTheme: () => void; } | null>(null);

export function useTheme(): { theme: string; toggleTheme: () => void; } {
    const result = React.useContext(ThemeContext);
    if (!result) {
        throw new Error('Called at the wrong time');
    }
    return result;
}

function getLang(language: string): Localization {
    let result: Localization = enUS;
    switch (language) {
        case 'cn':
            result = zhCN;
            break;
        case 'nl':
            result = nlNL;
            break;
    }
    return result;
}


export default function CustomThemeProvider({children}: { children: string | JSX.Element | JSX.Element[] }) {
    let startTheme: null | string = localStorage.getItem('hour_client_theme');
    if (startTheme !== 'dark' && startTheme !== 'light') {
        startTheme = 'light';
    }
    const [theme, setTheme] = useState(startTheme);
    const {i18n} = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    function toggleTheme() {
        const next = theme === "light" ? "dark" : "light";
        localStorage.setItem('hour_client_theme', next);
        setTheme(next);
    }

    const [darkTheme, setDarkTheme] = useState(createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#1976d2',
            },
        },

    }, getLang(i18n.language)));

    const [lightTheme, setLightTheme] = useState(createTheme({
        palette: {
            mode: 'light'
        },
    }, getLang(i18n.language)));

    useEffect(() => {
        if (language !== i18n.language) {
            setLanguage(i18n.language);
            setDarkTheme(createTheme({
                palette: {
                    mode: 'dark',
                    primary: {
                        main: '#1976d2',
                    },
                },
            }, getLang(i18n.language)));
            setLightTheme(createTheme({
                palette: {
                    mode: 'light'
                },
            }, getLang(i18n.language)));

        }
    }, [i18n.language]);

    return (<ThemeContext.Provider value={{theme, toggleTheme}}>
        <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
            {children}
        </ThemeProvider>
    </ThemeContext.Provider>);
}
