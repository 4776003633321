export class StatTags {
    private _count: number = 0;
    private _tags: Array<number> = [];

    get count(): number {
        return this._count;
    }

    set count(value: number) {
        this._count = value;
    }

    get tags(): Array<number> {
        return this._tags;
    }

    set tags(value: Array<number>) {
        this._tags = value;
    }
}
