import {
    Box,
    Card,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {DeviceFile, DeviceFileType} from "../../model/data/DeviceFile";
import LoadReloadComponent from "./LoadReloadComponent";
import {useApi} from "../../provider/ApiProvider";
import React, {useState} from "react";
import {LogbookFile} from "../../model/data/files/LogbookFile";
import {ProtrackJumpFile} from "../../model/data/files/ProtrackJumpFile";
import {useTranslation} from "react-i18next";
import ProtrackDataPlotComponent from "./ProtrackDataPlotComponent";
import {ToolkitTime} from "../../misc/ToolkitTime";

export default function ProtrackJumpFileComponent({deviceFile}: {deviceFile: DeviceFile}) {
    const {t} = useTranslation();
    const {logbookFileApi} = useApi();
    const [protrackDataFile, setProtrackDataFile] = useState<ProtrackJumpFile | null>(null);

    function meterToFeet(meter: number) {
        return Math.round(meter * 3.28084);
    }

    function meterSecToKmSec(meterSec: number) {
        return meterSec * 3.6;
    }

    async function loadData() {
        if (deviceFile.id) {
            const file = await logbookFileApi.get(deviceFile.id);
            if (file.type === DeviceFileType.PROTRACK_II_DATA) {
                setProtrackDataFile(file as ProtrackJumpFile);
            }
        }
    }

    return (<LoadReloadComponent reload={loadData}>
            <Card style={{marginTop: 30}}>
                <CardContent>
                    <Typography variant="h6" noWrap component="div"  sx={{ flexGrow: 1 }}>
                        {deviceFile.name}
                    </Typography>
                    {protrackDataFile ? (
                        <Box>
                            <ProtrackDataPlotComponent protrackJumpFile={protrackDataFile} />
                            <TableContainer component={Paper}>
                                <Table size="small" sx={{ minWidth: 100 }} aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.groundLevel')}:</TableCell>
                                            <TableCell>{protrackDataFile.groundLevel}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.temperature')}:</TableCell>
                                            <TableCell>{protrackDataFile.temperature}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.fileVersion')}:</TableCell>
                                            <TableCell>{protrackDataFile.fileVersion}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.deviceNumber')}:</TableCell>
                                            <TableCell>{protrackDataFile.deviceNumber}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.firmwareVersion')}:</TableCell>
                                            <TableCell>{protrackDataFile.firmwareVersion}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.serialNumber')}:</TableCell>
                                            <TableCell>{protrackDataFile.serialNumber}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.jumpNumber')}:</TableCell>
                                            <TableCell>{protrackDataFile.jumpNumber}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.jumpTimestamp')}:</TableCell>
                                            <TableCell>{ToolkitTime.formatDateTime(protrackDataFile.jumpTimestamp)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.exitAltitude')}:</TableCell>
                                            <TableCell>{meterToFeet(protrackDataFile.exitAltitude)} ft</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.deployAltitude')}:</TableCell>
                                            <TableCell>{meterToFeet(protrackDataFile.deployAltitude)} ft</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.freefallTime')}:</TableCell>
                                            <TableCell>{protrackDataFile.freefallTime}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.tasAverageSpeed')}:</TableCell>
                                            <TableCell>{meterSecToKmSec(protrackDataFile.tasAverageSpeed)} km/h</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.tasMaxSpeed')}:</TableCell>
                                            <TableCell>{meterSecToKmSec(protrackDataFile.tasMaxSpeed)} km/h</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.tasMinSpeed')}:</TableCell>
                                            <TableCell>{meterSecToKmSec(protrackDataFile.tasMinSpeed)} km/h</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.tasFirstHalfSpeed')}:</TableCell>
                                            <TableCell>{meterSecToKmSec(protrackDataFile.tasFirstHalfSpeed)} km/h</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.tasSecondHalfSpeed')}:</TableCell>
                                            <TableCell>{meterSecToKmSec(protrackDataFile.tasSecondHalfSpeed)} km/h</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.sasAverageSpeed')}:</TableCell>
                                            <TableCell>{meterSecToKmSec(protrackDataFile.sasAverageSpeed)} km/h</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.sasMaxSpeed')}:</TableCell>
                                            <TableCell>{meterSecToKmSec(protrackDataFile.sasMaxSpeed)} km/h</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.sasMinSpeed')}:</TableCell>
                                            <TableCell>{meterSecToKmSec(protrackDataFile.sasMinSpeed)} km/h</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.sasFirstHalfSpeed')}:</TableCell>
                                            <TableCell>{meterSecToKmSec(protrackDataFile.sasFirstHalfSpeed)} km/h</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.sasSecondHalfSpeed')}:</TableCell>
                                            <TableCell>{meterSecToKmSec(protrackDataFile.sasSecondHalfSpeed)} km/h</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.driveType')}:</TableCell>
                                            <TableCell>{protrackDataFile.driveType}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.lookupLine')}:</TableCell>
                                            <TableCell>{protrackDataFile.lookupLine}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{t('protrackdata.rfu')}:</TableCell>
                                            <TableCell>{protrackDataFile.rfu}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : <Box />}
                </CardContent>
            </Card>
    </LoadReloadComponent>);
}
