export class Year {
    private _year: string = '';
    private _count: number = 0;

    get year(): string {
        return this._year;
    }

    set year(value: string) {
        this._year = value;
    }

    get count(): number {
        return this._count;
    }

    set count(value: number) {
        this._count = value;
    }
}
