import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';

const lang : string | undefined = window.localStorage.getItem('language') || 'en';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        // fallbackLng: 'en',
        debug: true,
        lng: lang,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
