import React, {useState} from 'react';
import './App.css';
import {
    AppBar,
    Box,
    CssBaseline,
    Drawer,
    FormControl,
    IconButton,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Toolbar,
    Typography
} from "@mui/material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import {Outlet} from "react-router-dom";
import LogbookDrawer from "./ui/parts/LogbookDrawer";
import {useTheme} from "./provider/CustomThemeProvider";
import {asyncify} from "./misc/ToolkitAsync";
import {CN, GB, NL} from "country-flag-icons/react/3x2";
import {useTranslation} from "react-i18next";
import {useAuth} from "./provider/AuthProvider";
import ListItemButtonLink from "./ui/component/ListItemButtonLink";
import MenuItemLink from "./ui/component/MenuItemLink";

const drawerWidth = 240;

function App() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [permanentOpen, setPermanentOpen] = React.useState(true);
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const {logout, user} = useAuth();

    const drawer = LogbookDrawer();
    const theme = useTheme();

    const container = window !== undefined ? () => window.document.body : undefined;

    function closeMenu() {
        setAnchorEl(null);
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
        setPermanentOpen(!permanentOpen);
    };

    async function handleLanguageChange(event: SelectChangeEvent): Promise<void> {
        const lang = event.target.value as string;
        setLanguage(lang);
        window.localStorage.setItem('language', lang);
        await i18n.changeLanguage(lang);
    }

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{
                    ml: {sm: `${drawerWidth}px`},
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div"  sx={{ flexGrow: 1 }}>
                        {t('title')}
                    </Typography>
                    {/*<div>*/}
                    {/*    <FormControl size="small">*/}
                    {/*        <Select labelId="language-select-label"*/}
                    {/*                value={language}*/}
                    {/*                onChange={asyncify(handleLanguageChange)}*/}
                    {/*                id="language-simple-select">*/}
                    {/*            <MenuItem value={'en'}><GB width={30} /></MenuItem>*/}
                    {/*            <MenuItem value={'nl'}><NL width={30} /></MenuItem>*/}
                    {/*            <MenuItem value={'cn'}><CN width={30} /></MenuItem>*/}
                    {/*            <MenuItem value={'none'}>no lang</MenuItem>*/}
                    {/*        </Select>*/}
                    {/*    </FormControl>*/}
                    {/*</div>*/}
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                        <Menu id="menu-appbar" anchorEl={anchorEl}
                              anchorOrigin={{vertical: 'top', horizontal: 'right',}} keepMounted
                              transformOrigin={{vertical: 'top', horizontal: 'right',}} open={Boolean(anchorEl)}
                              onClose={closeMenu}>
                            <MenuItemLink to={'/profile'}>{t('menu.user.profile')}</MenuItemLink>
                            <MenuItemLink to={'/android_mobile_client_0.0.1.apk'}>{t('menu.user.app')}</MenuItemLink>
                            <MenuItem onClick={x => theme.toggleTheme()}>{t('menu.user.theme')}</MenuItem>
                            <MenuItem onClick={asyncify(logout)}>{t('menu.user.logout')}</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {sm: permanentOpen ? drawerWidth : 0}, flexShrink: {sm: 0}}}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="persistent"
                    open={permanentOpen}
                    sx={{
                        flexShrink: 0,
                        display: {xs: 'none', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, p: 3, width: {sm: permanentOpen ? `calc(100% - ${drawerWidth}px)` : `calc(100% - 0px)`}}}
            >
                <Toolbar/>
                <Outlet />
            </Box>
        </Box>);
}

export default App;
