import {
    Box,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Theme,
    useTheme
} from "@mui/material";
import {useState} from "react";
import {JumpTag} from "../../model/data/JumpTag";
import {useApi} from "../../provider/ApiProvider";
import LoadReloadComponent from "./LoadReloadComponent";
import {useTranslation} from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(id: number, selection: readonly number[], theme: Theme) {
    return {
        fontWeight:
            selection.indexOf(id) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function SelectTagsComponent({selection, setSelection}: {selection: Array<number>,
        setSelection: (value: Array<number>) => void}) {
    const theme = useTheme();
    const [tags, setTags] = useState<Map<Number, JumpTag>>(new Map());
    const {jumpTagApi} = useApi();
    const {t} = useTranslation();

    const handleChange = (event: SelectChangeEvent<typeof selection>) => {
        const {
            target: { value },
        } = event;
        setSelection(typeof value === 'string' ? value.split(',').map(parseInt) : value);
    };

    async function loadData() {
        setTags(await jumpTagApi.map());
    }

    return (
        <LoadReloadComponent reload={loadData}>
            <FormControl fullWidth>
                <InputLabel id="chip-label">{t('jump.tags')}</InputLabel>
                <Select
                    labelId="chip-label"
                    id="chip"
                    multiple
                    value={selection} onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label={t('jump.tags')} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={tags.get(value)?.name || "?"} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}>
                    {Array.from(tags.values()).map((tag) => (
                        <MenuItem key={tag.id} value={tag.id || 0} style={getStyles(tag.id || -1, selection, theme)}>
                            {tag.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </LoadReloadComponent>
    );
}
