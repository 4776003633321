import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {useState} from "react";
import {useTranslation} from "react-i18next";

export type DialogContextType = { openDialog: ((message: string, success: (() => void)) => void)};

const DialogContext = React.createContext<DialogContextType | null>(null);


export function useDialog(): DialogContextType {
    const result = React.useContext(DialogContext);
    if (!result) {
        throw new Error('Called at wrong moment');
    }
    return result;
}

export default function DialogProvider({children}: { children: string | JSX.Element | JSX.Element[] }) {
    const [open, setOpen] = useState(false);
    const [messageText, setMessageText] = useState<string>('');
    const [successFunction, setSuccessFunction] = useState<null | (() => void)>(null);

    const { t } = useTranslation();

    function openDialog(message: string, success: (() => void)) {
        setMessageText(message);
        setSuccessFunction(() => success);
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleYes() {
        setOpen(false);
        if (successFunction) {
            successFunction();
        }
    }

    return <DialogContext.Provider value={{openDialog}}>
        {children}
        <Dialog open={open} onClose={handleClose}
                aria-describedby="alert-dialog-description">
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {messageText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleYes}>{t('dialog.yes')}</Button>
                <Button onClick={handleClose} autoFocus>{t('dialog.no')}</Button>
            </DialogActions>
        </Dialog>
    </DialogContext.Provider>;
}
