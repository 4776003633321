import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import App from "../App";
import JumpPage from "../pages/jump/JumpPage";
import LoginPage from "../pages/LoginPage";
import {ProtectedRoute} from "../auth/ProtectedRoute";
import {LoggedoutRoute} from "../auth/LoggedoutRoute";
import TagsPage from "../pages/TagsPage";
import LocationsPage from "../pages/LocationsPage";
import EquipmentsPage from "../pages/EquipmentsPage";
import LocationEditPage from "../pages/LocationEditPage";
import EquipmentEditPage from "../pages/EquipmentEditPage";
import TagEditPage from "../pages/TagEditPage";
import JumpEditPage from "../pages/jump/JumpEditPage";
import UsersPage from "../pages/UsersPage";
import UserEditPage from "../pages/UserEditPage";
import {Role} from "../model/data/User";
import ProfileEditPage from "../pages/ProfileEditPage";
import StatsPage from "../pages/StatsPage";
import JumpViewPage from "../pages/jump/JumpViewPage";
import ImportPage from "../pages/impexport/ImportPage";
import UploadPage from "../pages/impexport/UploadPage";

export default function createRouter() {
    return <BrowserRouter>
        <Routes>
            <Route path='/' element={<ProtectedRoute><App/></ProtectedRoute>}>
                <Route path='/' element={<JumpPage/>}/>
                <Route path='jumps' element={<JumpPage/>}/>
                <Route path='jump/upload' element={<UploadPage/>}/>
                <Route path='jump/:id' element={<JumpEditPage/>}/>
                <Route path='jump/view/:id' element={<JumpViewPage/>}/>
                <Route path='tags' element={<TagsPage/>}/>
                <Route path='tag/:id' element={<TagEditPage/>}/>
                <Route path='locations' element={<LocationsPage/>}/>
                <Route path='import' element={<ImportPage/>}/>
                <Route path='location/:id' element={<LocationEditPage/>}/>
                <Route path='equipments' element={<EquipmentsPage/>}/>
                <Route path='equipment/:id' element={<EquipmentEditPage/>}/>
                <Route path='profile' element={<ProfileEditPage/>}/>
                <Route path='stats' element={<StatsPage/>}/>
                <Route path='users' element={<ProtectedRoute allowedRoles={[Role.RESELLER, Role.ADMIN]}><UsersPage/></ProtectedRoute>}/>
                <Route path='user/:id' element={<ProtectedRoute allowedRoles={[Role.RESELLER, Role.ADMIN]}><UserEditPage/></ProtectedRoute>}/>
            </Route>
            <Route path='/login' element={<LoggedoutRoute><LoginPage/></LoggedoutRoute>}/>
        </Routes>
    </BrowserRouter>
}
