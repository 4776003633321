import axios, {AxiosRequestConfig} from "axios";
import {AxiosResponse} from "axios/index";
import {Page} from "../model/http/Page";
import {GridPaginationModel} from "@mui/x-data-grid/models/gridPaginationProps";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";

const http = axios.create({withCredentials: true});

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export class Api {
    protected baseUrl: string;
    protected errorHandlers = new Map<number, (e: AxiosResponse) => Promise<any>>();
    private _typeDefiner: undefined | (<Type>(data: any) => Type);

    constructor(path: string) {
        this.baseUrl = SERVER_URL + path;
    }

    set typeDefiner(value: (<Type>(data: any) => Type) | undefined) {
        this._typeDefiner = value;
    }

    public addErrorHandler(status: number, handler: (e: AxiosResponse) => Promise<any>): void {
        this.errorHandlers.set(status, handler);
    }

    public async get(url?: string): Promise<any> {
        return this.handleRequest(http.get(this.baseUrl + (url ?? '')));
    }

    public async getObject<Type>(type: Type, url?: string): Promise<Type> {
        return this.handleObjectRequest(type, this.get(url));
    }

    public async getObjects<Type>(type: Type, url?: string): Promise<Array<Type>> {
        return this.handleObjectsRequest(type, this.get(url));
    }

    public async getPage<Type>(type: Type, gridPaginationModel?: GridPaginationModel, gridSortModel?: GridSortModel,
                               url?: string): Promise<Page<Type>> {
        return this.handlePagedRequest(type, this.get(this.addPagination(url, gridPaginationModel, gridSortModel)));
    }

    public async post(data: any, url?: string, config?: AxiosRequestConfig): Promise<any> {
        return this.handleRequest(http.post(this.baseUrl + (url ?? ''), data, config));
    }

    public async postObject<Type>(type: Type, data: any, url?: string): Promise<Type> {
        return this.handleObjectRequest(type, this.post(data, url));
    }

    public async patch(data: any, url?: string): Promise<any> {
        return this.handleRequest(http.patch(this.baseUrl + (url ?? ''), data));
    }

    public async delete(data: any, url?: string): Promise<any> {
        return this.handleRequest(http.delete(this.baseUrl + (url ?? ''), data));
    }

    protected async handleObjectRequest<Type>(type: Type, response: Promise<any>): Promise<Type> {
        const data = await response;
        const result =  Object.create(this.getType(type, data) as any);
        const temp: any = {};
        for (const key of Object.keys(data)) {
            temp['_' + key] = data[key];
        }
        Object.assign(result, temp);
        return result as Type;
    }

    private getType<Type>(type: Type, data: any): Type {
        if (this._typeDefiner) {
            type = this._typeDefiner(data) as Type;
        }
        return type;
    }

    protected async handlePagedRequest<Type>(type: Type, response: Promise<any>): Promise<Page<Type>> {
        const data = await response;
        return new Page<Type>(data.total, (data.content as Array<any>).map((data) => {
            const result = Object.create(this.getType(type, data) as any);
            const temp: any = {};
            for (const key of Object.keys(data)) {
                temp['_' + key] = data[key];
            }
            Object.assign(result, temp);
            return result;
        }));
    }

    protected async handleObjectsRequest<Type>(type: Type, response: Promise<any>): Promise<Array<Type>> {
        return (await response as Array<any>).map((data) => {
            const result = Object.create(this.getType(type, data) as any);
            const temp: any = {};
            for (const key of Object.keys(data)) {
                temp['_' + key] = data[key];
            }
            Object.assign(result, temp);
            return result;
        });
    }

    protected async handleRequest(response: Promise<AxiosResponse>): Promise<any> {
        try {
            const resp = (await response);
            return resp.data;
        } catch (e: any) {
            const exceptionResponse = (e.response) as AxiosResponse;
            const handler = this.errorHandlers.get(exceptionResponse.status);
            if (!handler) {
                throw e;
            }
            const result = await handler(exceptionResponse);
            if (!result) {
                throw e;
            }
            return result;
        }
    }

    private addPagination(url?: string, gridPaginationModel?: GridPaginationModel, gridSortModel?: GridSortModel):
            string {
        let result = url || '';
        if (gridPaginationModel) {
            if (!result.includes('?')) {
                result += '?'
            } else {
                result += '&';
            }
            result += 'page=' + gridPaginationModel.page
                + '&size=' + gridPaginationModel.pageSize;
        }
        if (gridSortModel && gridSortModel.length > 0) {
            if (!result.includes('?')) {
                result += '?'
            } else {
                result += '&';
            }
            result += 'sort=' + gridSortModel[0].field + ',' + gridSortModel[0].sort || 'asc';
        }
        return result;
    }
}
