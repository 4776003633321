export class JumpTag {
    private readonly _id: number | null = null;
    private _label: string = '';

    get id(): number | null {
        return this._id;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get name(): string {
        return this._label;
    }
}
