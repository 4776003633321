import {useTranslation} from "react-i18next";
import {Box, Button, Card, CardContent, FormControl, TextField} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {useSnackbar} from "notistack";
import {CrudApi} from "../../api/CrudApi";
import {Idable} from "../../model/data/Idable";
import LoadReloadComponent from "./LoadReloadComponent";
import {asyncify} from "../../misc/ToolkitAsync";
import FormLoader from "./FormLoader";

export default function EditComponent<Type extends Idable>({api, setSource, getSavable, getAsyncSavable, children,
                                                               successSavePage}:
        {api: CrudApi<Type>, setSource: (data: Type | null) => void, getSavable?: () => Type,
            getAsyncSavable?: () => Promise<Type>
            children: string | JSX.Element | JSX.Element[], successSavePage: string }) {
    const {t} = useTranslation();
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const param = useParams();

    async function loadData() {
        if (param.id !== undefined) {
            if (param.id === 'new') {
                setSource(null);
            } else {
                setSource(await api.get(parseInt(param.id)));
            }
        }
    }

    async function handleSave() {
        let saveObject: Type | null = null;
        if (getSavable) {
            saveObject = getSavable();
        } else if (getAsyncSavable) {
            saveObject = await getAsyncSavable();
        }
        if (saveObject != null) {
            try {
                await api.save(saveObject);
                snackbar.enqueueSnackbar(saveObject.id === null ? t('form.createSuccess') : t('form.updateSuccess'));
                navigate(successSavePage);
            } catch (e) {
                snackbar.enqueueSnackbar(saveObject.id === null ? t('form.createFailed') : t('form.updateFailed'),
                    {variant: 'error'});
            }
        }
    }

    useEffect(asyncify(loadData), [param]);

    return (<LoadReloadComponent reload={loadData}>
        <Card variant="outlined">
            <CardContent>
                <FormLoader submitFunction={handleSave}>
                    {children}
                </FormLoader>
            </CardContent>
        </Card></LoadReloadComponent>);
}
