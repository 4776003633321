import {useApi} from "../provider/ApiProvider";
import {GridColDef} from '@mui/x-data-grid';
import DataTable from "../ui/component/DataTable";
import {useTranslation} from "react-i18next";
import {Box, Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useNavigate} from "react-router-dom";
import {DefaultFabStyle} from "../ui/component/Fab";


export default function LocationsPage() {
    const {t} = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('location.name'),
            type: 'string',
            flex: 0.5,
            minWidth: 200,
            editable: false,
        },
        {
            field: 'coordinate',
            headerName: t('location.coordinate'),
            type: 'string',
            flex: 0.5,
            minWidth: 200,
            editable: false,
        }
    ];

    const {locationApi} = useApi();

    return (<DataTable api={locationApi} columns={columns} deletable editLocation={'/location/'}/>);
}
