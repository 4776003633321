import {useApi} from "../provider/ApiProvider";
import {useTranslation} from "react-i18next";
import {Button, FormControl, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import EditComponent from "../ui/component/EditComponent";
import {JumpTag} from "../model/data/JumpTag";

export default function TagEditPage() {
    const {t} = useTranslation();
    const {jumpTagApi} = useApi();
    const [tag, setTag] = useState<JumpTag | null>(null);
    const [name, setName] = useState('');

    useEffect(() => {
        if (tag) {
            setName(tag.name);
        }
    }, [tag]);

    function getLocation(): JumpTag {
        let result = tag || new JumpTag();
        result.label = name;
        return result;
    }

    return (<EditComponent api={jumpTagApi} setSource={setTag} getSavable={getLocation} successSavePage='/tags'>
        <FormControl margin='normal' fullWidth>
            <TextField variant="standard" required id="label" label={t('tags.label')}
                       value={name} onChange={e => setName(e.target.value)}/>
        </FormControl>
        <FormControl margin='normal'>
            <Button type='submit' variant='contained'>
                {tag?.id == null ? t('form.create'):t('form.update')}
            </Button>
        </FormControl>
    </EditComponent>);
}
