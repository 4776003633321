import {IdableAndNameable} from "./IdableAndNameable";

export class Location implements IdableAndNameable {
    private readonly _id: number | null = null;
    private _name: string = '';
    private _coordinate: string | null = null;


    get id(): number | null {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get coordinate(): string | null {
        return this._coordinate;
    }

    set coordinate(value: string | null) {
        this._coordinate = value;
    }
}
