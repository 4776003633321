import {Box, FormControl, FormControlLabel} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {Line} from "react-chartjs-2";
import {FlySightFileJump} from "../../model/data/files/FlySightFileJump";
import Checkbox from "@mui/material/Checkbox";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const backgroundColor = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
];
const borderColor = [
    'rgba(255, 99, 132, 1)',
    'rgb(0,152,255)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
];

export default function FlySightJumpDataPlotComponent({flysightFileJump}: {flysightFileJump: FlySightFileJump}) {
    const {t} = useTranslation();
    const [chartData, setChartData] = useState<any>({labels: [], datasets: []});
    const [limit, setLimit] = useState<boolean>(true);

    function pressureToFeet(point: number, groundLevel: number) {
        return 145366.45 * (1-Math.pow((point / 10) / 1013.25, 0.190284));
    }

    function feetToM(feet: number) {
        return feet / 3.28084;
    }
    function mToFeet(meter: number) {
        return meter * 3.28084;
    }

    function windowAverage(input: Array<number>, window: number): Array<number> {
        const averagedSpeed = [0];
        const averageRange = 10;
        for (let i = 1; i < input.length; i++) {
            let count = 0;
            let dataPoint = 0;
            const space = Math.min(averageRange, i, input.length - i);
            for (let j = Math.max(i - space, 1); j < i + space; j++) {
                dataPoint += input[j];
                ++count;
            }
            averagedSpeed.push(dataPoint/ count);
        }
        return averagedSpeed;
    }

    function delta(input: Array<number>, shift: number): Array<number> {
        const result = [0];
        for (let i = 1; i < input.length; i++) {
            result.push((input[i] - input[i - 1]) / shift);
        }
        return result;
    }

    useEffect(() => {
        const labels: Array<number> = [];
        let lines = flysightFileJump.lines;
        if (limit) {
            let min = lines[0].hMSL;
            let max = lines[0].hMSL;
            let reachedFloor = 0;
            let from= 0;
            let to= 0;
            for (let i = 1; i < lines.length; i++) {
                if (lines[i].hMSL > max) {
                    from = i;
                    to = i;
                    reachedFloor = i;
                    max = lines[i].hMSL;
                    min = lines[i].hMSL;
                }
                if (lines[i].hMSL < min) {
                    to = i;
                    min = lines[i].hMSL;
                    if ((lines[reachedFloor].hMSL - min) > 10) {
                        reachedFloor = i;
                    }
                }
            }
            lines = lines.slice(Math.max(from - 40, 0), Math.min(reachedFloor + 40, lines.length));
        }

        for (let i = 0; i < lines.length; ++i) {
            labels.push((flysightFileJump.lines[i].time - flysightFileJump.lines[0].time) / 1000);
        }
        const datasets: Array<any> = [];


        let altitude: Array<number> = lines.map(point => mToFeet(point.hMSL));
        const ground = Math.min(...altitude);
        altitude = altitude.map(alt => alt - ground);

        const speed = lines.map(point => point.velD * 3.6);
        const speedH = lines
            .map(point => Math.sqrt(point.velE * point.velE + point.velN * point.velN) * 3.6);
        const speedT = lines
            .map(point => Math.sqrt(point.velE * point.velE + point.velN * point.velN + point.velD * point.velD) * 3.6);

        datasets.push({
            label: t('dataPlot.altitudeLabel'),
            data: altitude,
            borderColor: borderColor[1],
            backgroundColor: backgroundColor[1],
            yAxisID: "y-axis-alt",
        });

        datasets.push({
            label: t('dataPlot.speedLabel'),
            data: speed,
            borderColor: borderColor[0],
            backgroundColor: backgroundColor[0],
            yAxisID: "y-axis-speed",
        });

        datasets.push({
            hidden: true,
            label: t('dataPlot.speedLabelHorizontal'),
            data: speedH,
            borderColor: borderColor[2],
            backgroundColor: backgroundColor[2],
            yAxisID: "y-axis-speed",
        });

        datasets.push({
            hidden: true,
            label: t('dataPlot.speedLabelTotal'),
            data: speedT,
            borderColor: borderColor[3],
            backgroundColor: backgroundColor[3],
            yAxisID: "y-axis-speed",
        });

        const data = {
            labels: labels,
            datasets: datasets,
        };
        setChartData(data);
    }, [flysightFileJump, limit]);

    const options2 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: t('dataPlot.graphName'),
            },
        },
    };

    return (<Box>
        <Line options={options2} data={chartData}/>
        <FormControl margin='normal' fullWidth>
            <FormControlLabel control={<Checkbox checked={limit} onChange={(event) => setLimit(event.target.checked)}/>} label={'Limit'}/>
        </FormControl>
    </Box>);
}
