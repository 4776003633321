import {Idable} from "../Idable";

export class StatLocation implements Idable {
    private _name: string = '';
    private _count: number = 0;
    private _id: number | null = null;

    get id(): number | null {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get count(): number {
        return this._count;
    }

    set count(value: number) {
        this._count = value;
    }
}
