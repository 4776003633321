import {Idable} from "./Idable";
import {IdableAndNameable} from "./IdableAndNameable";

export enum DeviceFileType {
    PROTRACK_II_STATUS = 1, PROTRACK_II_SETUP = 2, PROTRACK_II_DATA = 3, FLYSIGHT_DATA = 4
}

export class DeviceFile implements IdableAndNameable {
    private readonly _id: number | null = null;
    private _filename: string = '';
    private _deviceId: number = 0;
    private _type: DeviceFileType = DeviceFileType.PROTRACK_II_DATA;
    private _createdAt = 0;
    private _jumpId: number | null = null;

    get id(): number | null {
        return this._id;
    }

    get name(): string {
        return this._filename;
    }

    get filename(): string {
        return this._filename;
    }

    set filename(value: string) {
        this._filename = value;
    }

    get deviceId(): number {
        return this._deviceId;
    }

    set deviceId(value: number) {
        this._deviceId = value;
    }

    get type(): DeviceFileType {
        return this._type;
    }

    set type(value: DeviceFileType) {
        this._type = value;
    }

    get createdAt(): number {
        return this._createdAt;
    }

    set createdAt(value: number) {
        this._createdAt = value;
    }

    get jumpId(): number | null {
        return this._jumpId;
    }

    set jumpId(value: number | null) {
        this._jumpId = value;
    }
}
