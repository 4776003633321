import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SnackbarProvider} from "notistack";
import DialogProvider from "./provider/DialogProvider";
import CustomThemeProvider from "./provider/CustomThemeProvider";
import AuthProvider from "./provider/AuthProvider";
import createRouter from "./router/Router";

import "./i18n"
import ApiProvider from "./provider/ApiProvider";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AuthProvider>
            <ApiProvider>
                <CustomThemeProvider>
                    <SnackbarProvider maxSnack={7}>
                        <DialogProvider>
                            {createRouter()}
                        </DialogProvider>
                    </SnackbarProvider>
                </CustomThemeProvider>
            </ApiProvider>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
