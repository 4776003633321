import * as React from 'react';
import {useState} from "react";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {AuthApi} from "../api/AuthApi";
import LoadReloadComponent from "../ui/component/LoadReloadComponent";
import {useTranslation} from "react-i18next";
import {Me} from "../model/auth/Me";
import {Role} from "../model/data/User";

export type DialogContextType = { openDialog: ((message: string, success: (() => void)) => void)};

type AuthProviderType = {
    user: Me | null,
    reauthenticate: () => Promise<void>,
    logout: () => Promise<void>,
    authenticate: (username: string, password: string) => Promise<boolean>,
    authApi: AuthApi,
    hasRole: (roles: Array<Role | null>) => boolean
};

const AuthContext = React.createContext<AuthProviderType | null>(null);


export function useAuth(): AuthProviderType {
    const result = React.useContext(AuthContext);
    if (!result) {
        throw new Error('Called at the wrong time');
    }
    return result;
}


export default function AuthProvider({children}: {children: JSX.Element }) {
    const [user, setUser] = useState<Me | null>(null);

    async function reauthenticate(): Promise<void> {
        const me = await authData.authApi.me();
        setUser(me.username ? me : null);
    }

    async function authenticate(username: string, password: string): Promise<boolean> {
        const me = await authData.authApi.login(username, password);
        const result = !!me.username;
        setUser(me.username ? me : null);
        return result;
    }

    async function logout(): Promise<void> {
        await authData.authApi.logout();
        setUser(null);
    }

    function hasRole(roles: Array<Role | null>): boolean {
        return roles.includes(user?.userRole || null);
    }

    const authData: AuthProviderType = {user, authenticate, reauthenticate,
        authApi: new AuthApi(), logout, hasRole};
    const {t} = useTranslation();

    authData.reauthenticate = reauthenticate;
    return (<AuthContext.Provider value={authData}>
            <LoadReloadComponent reload={reauthenticate} message={t('auth.failed_loading')} autoRetry={10}>
                {children}
            </LoadReloadComponent>
        </AuthContext.Provider>);
}
