import {useTranslation} from "react-i18next";
import {Button, Card, CardContent, FormControl} from "@mui/material";
import {useState} from "react";
import {MuiFileInput} from "mui-file-input";
import FormLoader from "../../ui/component/FormLoader";
import {Api} from "../../api/Api";

export default function UploadPage() {
    const {t} = useTranslation();
    const [files, setFiles] = useState<Array<File>>([])
    const api = new Api('/import');

    async function importFile() {
        if (files) {
            for (let file of files.sort((f1, f2) => f1.name.localeCompare(f2.name))) {
                const formData = new FormData();
                formData.append('file', file);
                await api.post(formData, '/logfile', {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }
            setFiles([]);
        }
    }

    return (<Card variant="outlined">
        <CardContent>
            <FormLoader submitFunction={importFile}>
                <FormControl margin='normal' fullWidth>
                    <MuiFileInput multiple value={files} onChange={setFiles} label={t('upload.logfiles')} />
                </FormControl>
                <FormControl margin='normal'>
                    <Button type='submit' variant='contained'>
                        {t('form.upload')}
                    </Button>
                </FormControl>
            </FormLoader>
        </CardContent>
    </Card>);
}
