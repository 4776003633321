import moment from "moment";

export class ToolkitTime {
    public static formatDate(input: number): string {
        return moment.unix(input).format('DD-MM-yyyy');
    }

    public static formatDateTime(input: number): string {
        return moment.unix(input).format("yyyy-MM-DD HH:mm:ss");
    }

    public static dynamicFormatDateTime(input: number | null): string {
        let result = '';
        if (input) {
            result = moment.unix(input).format("yyyy-MM-DD HH:mm:ss");
            if (result.includes(" 00:00:00")) {
                result = result.split(' ')[0];
            }
        }
        return result;
    }

    public static getWeek(input: number): string {
        return moment.unix(input).format("w");
    }


    public static now(): number {
        return moment().unix();
    }

    public static getWeekId(input: number): number {
        return parseInt(moment.unix(input).format("yyyy")) * 100
            + parseInt(moment.unix(input).format("w"));
    }

    public static formatDurationSeconds(input: number): string {
        return ToolkitTime.formatDurationMinutes(Math.round(input / 60));
    }

    public static formatDurationMinutes(input: number): string {
        const minutes = input % 60;
        const hours = Math.floor(input / 60);
        return "" + hours + ":" + ("" + minutes).padStart(2, '0');
    }

    static moveWeekId(from: number, change: number) {
        return ToolkitTime.getWeekId(moment('' + Math.floor(from / 100) + '-' + (from % 100), 'yyyy-w')
            .add(change, "weeks").unix());
    }

    static formatWeekId(input: number) {
        return '' + Math.floor(input / 100) + '/' + (input % 100);
    }
}
