import {Box, Divider, List, ListItem, ListItemIcon, ListItemText, Toolbar} from "@mui/material";
import ParaglidingIcon from '@mui/icons-material/Paragliding';
import LabelIcon from '@mui/icons-material/Label';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BackpackIcon from '@mui/icons-material/Backpack';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import React, {SyntheticEvent} from "react";
import {useNavigate} from "react-router-dom";
import ListItemButtonLink from "../component/ListItemButtonLink";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../provider/AuthProvider";
import {Role} from "../../model/data/User";

export default function LogbookDrawer() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {hasRole} = useAuth();

    function route(e: SyntheticEvent) {
        e.preventDefault();
        navigate("/jumps");
    }

    return (
        <Box>
            <Toolbar/>
            <Divider/>
            <List>
                <ListItem disablePadding>
                    <ListItemButtonLink to={'/Jumps'}>
                        <ListItemIcon>
                            <ParaglidingIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('menu.jumps')}/>
                    </ListItemButtonLink>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButtonLink to={'/tags'}>
                        <ListItemIcon>
                            <LabelIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('menu.tags')}/>
                    </ListItemButtonLink>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButtonLink to={'/locations'}>
                        <ListItemIcon>
                            <LocationOnIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('menu.locations')}/>
                    </ListItemButtonLink>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButtonLink to={'/equipments'}>
                        <ListItemIcon>
                            <BackpackIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('menu.equipments')}/>
                    </ListItemButtonLink>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButtonLink to={'/stats'}>
                        <ListItemIcon>
                            <InsertChartIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('menu.stats')}/>
                    </ListItemButtonLink>
                </ListItem>
                {hasRole([Role.COLLECTOR, Role.ADMIN])?(<Box>
                    <ListItem disablePadding>
                        <ListItemButtonLink to={'/users'}>
                            <ListItemIcon>
                                <BackpackIcon/>
                            </ListItemIcon>
                            <ListItemText primary={t('menu.users')}/>
                        </ListItemButtonLink>
                    </ListItem>
                </Box>):''}
            </List>
        </Box>
    );
}
