import {IdableAndNameable} from "./IdableAndNameable";

export enum Role {
    USER = 0, RESELLER = 1, ADMIN = 2, COLLECTOR = 3
}

export class User implements IdableAndNameable {
    private readonly _id: number | null = null;
    private _username: string = '';
    private _password: string | null = null;
    private _userRole: Role = Role.USER;
    private _owner: number | null = null;

    get id(): number | null {
        return this._id;
    }

    get name(): string {
        return this._username;
    }

    get username(): string {
        return this._username;
    }

    set username(value: string) {
        this._username = value;
    }

    get role(): Role {
        return this._userRole;
    }

    set role(value: Role) {
        this._userRole = value;
    }

    get owner(): number | null {
        return this._owner;
    }

    set owner(value: number | null) {
        this._owner = value;
    }

    set password(value: string | null) {
        this._password = value;
    }
}
