import {useApi} from "../../provider/ApiProvider";
import {useTranslation} from "react-i18next";
import {Button, FormControl, InputLabel, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import EditComponent from "../../ui/component/EditComponent";
import {Jump} from "../../model/data/Jump";
import GenericSelectComponent from "../../ui/component/GenericSelectComponent";
import SelectTagsComponent from "../../ui/component/SelectTagsComponent";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment, {Moment} from "moment";
import {MuiFileInput} from "mui-file-input";
import {DeviceFile} from "../../model/data/DeviceFile";
import {asyncify} from "../../misc/ToolkitAsync";

export default function JumpEditPage() {
    const {t} = useTranslation();
    const {jumpApi, locationApi, equipmentApi} = useApi();
    const [jump, setJump] = useState<Jump | null>(null);
    const [jumpNumber, setJumpNumber] = useState<number | string>('');
    const [freeFallTime, setFreeFallTime] = useState<number>(0);
    const [exitAltitude, setExitAltitude] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [location, setLocation] = useState<number | null>(null);
    const [equipment, setEquipment] = useState<number | null>(null);
    const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
    const [jumpDate, setJumpDate] = useState<Moment | null>(moment());
    const [file, setFile] = useState<File | null>(null)
    const [deviceFiles, setDeviceFiles] = useState<Array<DeviceFile>>([]);

    useEffect(asyncify(async() => {
        if (jump) {
            setJumpNumber(jump.number || '');
            setJumpDate(jump.timestamp ? moment.unix(jump.timestamp) : moment());
            setFreeFallTime(jump.freeFallTime || 0);
            setExitAltitude(jump.exitAltitude || 0);
            setDescription(jump.description || '');
            setLocation(jump.location);
            setEquipment(jump.equipment);
            setSelectedTags(jump.tags);

            setDeviceFiles(await jumpApi.getJumpFiles(jump));
        }
    }), [jump]);

    async function getJump(): Promise<Jump> {
        const result = jump || new Jump();
        result.number = jumpNumber === '' ? null: (jumpNumber as number);
        result.timestamp = jumpDate?.unix() || null;
        result.freeFallTime = freeFallTime || null;
        result.exitAltitude = exitAltitude || null;
        result.description = description;
        result.location = location;
        result.equipment = equipment;
        result.tags = selectedTags;

        if (file) {
            result.fileContent = await file.text();
            result.fileName = file.name;
        }
        return result;
    }

    return (<EditComponent api={jumpApi} setSource={setJump} getAsyncSavable={getJump} successSavePage='/jumps'>
        <FormControl margin='normal' fullWidth>
            <TextField variant="standard" id="label" label={t('jump.number')}
                       type="number"
                       value={jumpNumber} onChange={e => setJumpNumber(parseInt(e.target.value))}/>
        </FormControl>
        <FormControl margin='normal' fullWidth>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker value={jumpDate} onChange={setJumpDate} />
            </LocalizationProvider>
        </FormControl>
        <FormControl margin='normal' fullWidth>
            <TextField variant="standard" id="label" label={t('jump.freeFallTime')}
                       type="number"
                       value={freeFallTime} onChange={e => setFreeFallTime(parseInt(e.target.value))}/>
        </FormControl>
        <FormControl margin='normal' fullWidth>
            <TextField variant="standard" id="label" label={t('jump.exitAltitude')}
                       type="number"
                       value={exitAltitude} onChange={e => setExitAltitude(parseInt(e.target.value))}/>
        </FormControl>
        <FormControl margin='normal' fullWidth>
            <TextField variant="standard" id="label" label={t('jump.description')}
                       value={description} onChange={e => setDescription(e.target.value)}/>
        </FormControl>

        <FormControl margin='normal' fullWidth>
            <InputLabel id="select-location-label">{t('jump.location')}</InputLabel>
            <GenericSelectComponent labelId='select-location-label'
                                    id={location} setId={setLocation} api={locationApi} />
        </FormControl>
        <FormControl margin='normal' fullWidth>
            <InputLabel id="select-equipment-label">{t('jump.equipment')}</InputLabel>
            <GenericSelectComponent labelId='select-equipment-label'
                                    filter={e => e.active}
                                    id={equipment} setId={setEquipment} api={equipmentApi} />
        </FormControl>
        <FormControl margin='normal' fullWidth>
            <SelectTagsComponent selection={selectedTags} setSelection={setSelectedTags} />
        </FormControl>
        <FormControl margin='normal' fullWidth>
            <MuiFileInput value={file} onChange={setFile} label='Log file' />
        </FormControl>
        <ul>
            {deviceFiles.map((file) => (<li key={file.id}>{file.name}</li>))}
        </ul>
        <FormControl margin='normal'>
            <Button type='submit' variant='contained'>
                {jump?.id == null ? t('form.create'):t('form.update')}
            </Button>
        </FormControl>
    </EditComponent>);
}
