import {useAuth} from "../provider/AuthProvider";
import {Navigate} from "react-router-dom";
import {Box} from "@mui/material";
import {Role} from "../model/data/User";

export function ProtectedRoute({children, allowedRoles}:
                                   { children: string | JSX.Element | JSX.Element[], allowedRoles?: Array<Role>}) {
    const {user} = useAuth();

    if (!user) {
        return (<Navigate to="/login"/>);
    } else if (allowedRoles) {
        if (!user.userRole || !allowedRoles.includes(user.userRole)) {
            return (<Navigate to="/"/>);
        }
    }
    return (<Box>{children}</Box>);
}
