import {LogbookFile} from "./LogbookFile";

export class FlySightLine {
    private _hMSL: number = 0;
    private _time: number = 0;
    private _velD: number = 0;
    private _velN: number = 0;
    private _velE: number = 0;

    get time(): number {
        return this._time;
    }

    get hMSL(): number {
        return this._hMSL;
    }

    get velD(): number {
        return this._velD;
    }

    get velN(): number {
        return this._velN;
    }

    get velE(): number {
        return this._velE;
    }
}

export class FlySightFileJump extends LogbookFile {
    private _lines: Array<FlySightLine> = [];

    get lines(): Array<FlySightLine> {
        return this._lines;
    }
}
