import {Alert, Box, Button, Grid, LinearProgress, Skeleton} from "@mui/material";
import {asyncify} from "../../misc/ToolkitAsync";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function LoadReloadComponent({children, reload, message, autoRetry}: {children: JSX.Element | JSX.Element[]
    reload: (() => Promise<void>), autoRetry?: number,
        message?: string}): JSX.Element {
    const [loading, setLoading] = useState(true);
    const [loadingFailed, setLoadingFailed] = useState(false);
    const [progress, setProgress] = useState(0);
    const [timer, setTimer] = useState<null|NodeJS.Timer>(null);
    const {t} = useTranslation();

    async function loadData() {
        stopTimer();
        setLoading(true);
        setProgress(0);
        setLoadingFailed(false);
        try {
            await reload();
            setLoadingFailed(false);
        } catch (e) {
            setLoadingFailed(true);
        } finally {
            setLoading(false);
        }
    }

    function startTimer() {
        let resultTimer: any = undefined;
        if (autoRetry) {
            if (timer) {
                clearInterval(timer);
            }
            resultTimer = setInterval(() => {
                setProgress((oldProgress) => {
                    return Math.min(oldProgress + (100 / 2 / autoRetry), 100);
                });
            }, 500);
            setTimer(resultTimer);
        }
    }

    function stopTimer() {
        if (timer) {
            clearInterval(timer);
            setTimer(null);
        }
    }

    useEffect(() => {
        asyncify(loadData)();
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        }
    }, []);

    useEffect(() => {
        if (autoRetry && loadingFailed) {
            startTimer();
        }
    }, [loadingFailed]);

    useEffect(() => {
        if (progress === 100) {
            asyncify(loadData)();
        }
    }, [progress]);

    if (loadingFailed) {
        return (
            <Grid container justifyContent="center">
                <Alert variant='filled' color='error'
                       action={<Button onClick={asyncify(loadData)} color="inherit" size="small">{t('load_reload.reload')}</Button>}>
                    {message ?? t('message.loadReloadFailed')}
                    {autoRetry ? <LinearProgress color='error' variant="determinate" value={progress} />: ""}
                </Alert>
            </Grid>);
    } else if (loading) {
        return <Skeleton variant='rounded'/>
    } else if (Array.isArray(children)) {
        return <Box>{children}</Box>;
    } else {
        return children as JSX.Element;
    }
}
