import {CrudApi} from "./CrudApi";
import {Jump} from "../model/data/Jump";
import {DeviceFile} from "../model/data/DeviceFile";

export class JumpApi extends CrudApi<Jump> {
    constructor() {
        super('/jump', Jump.prototype);
    }

    async updateTags(jump: Jump, toAdd: number[], toDelete: number[]): Promise<void> {
        await this.api.post({add: toAdd, delete: toDelete} , '/' + jump.id + '/tags');
    }

    async getJumpFiles(jump: Jump): Promise<Array<DeviceFile>> {
        return await this.api.getObjects(DeviceFile.prototype, '/' + jump.id + '/files')
    }
}
