import {Idable} from "./Idable";
import {IdableAndNameable} from "./IdableAndNameable";

export class Equipment implements IdableAndNameable {
    private readonly _id: number | null = null;
    private _name: string = '';
    private _canopy: string | null = null;
    private _canopySerial: string | null = null;
    private _reserve: string | null = null;
    private _reserveSerial: string | null = null;
    private _aad: string | null = null;
    private _aadSerial: string | null = null;
    private _container: string | null = null;
    private _containerSerial: string | null = null;
    private _active: boolean = true;
    private _lastRepack: number | null = null;

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get canopy(): string | null {
        return this._canopy;
    }

    set canopy(value: string | null) {
        this._canopy = value;
    }

    get canopySerial(): string | null {
        return this._canopySerial;
    }

    set canopySerial(value: string | null) {
        this._canopySerial = value;
    }

    get reserve(): string | null {
        return this._reserve;
    }

    set reserve(value: string | null) {
        this._reserve = value;
    }

    get reserveSerial(): string | null {
        return this._reserveSerial;
    }

    set reserveSerial(value: string | null) {
        this._reserveSerial = value;
    }

    get aad(): string | null {
        return this._aad;
    }

    set aad(value: string | null) {
        this._aad = value;
    }

    get aadSerial(): string | null {
        return this._aadSerial;
    }

    set aadSerial(value: string | null) {
        this._aadSerial = value;
    }

    get container(): string | null {
        return this._container;
    }

    set container(value: string | null) {
        this._container = value;
    }

    get containerSerial(): string | null {
        return this._containerSerial;
    }

    set containerSerial(value: string | null) {
        this._containerSerial = value;
    }

    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = value;
    }

    get lastRepack(): number | null {
        return this._lastRepack;
    }

    set lastRepack(value: number | null) {
        this._lastRepack = value;
    }

    get id(): number | null {
        return this._id;
    }
}
