
export class Page<T> {
    private readonly _total: number;
    private readonly _data: Array<T> = [];

    constructor(total: number, data: Array<T>) {
        this._total = total;
        this._data = data;
    }

    get data(): Array<T> {
        return this._data;
    }

    get total(): number {
        return this._total;
    }
}
