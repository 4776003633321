import {DeviceFileType} from "../DeviceFile";
import {Idable} from "../Idable";

export class LogbookFile implements Idable {
    private _type: DeviceFileType = DeviceFileType.PROTRACK_II_DATA;
    private _id: number | null = null;

    get id(): number | null {
        return this._id;
    }

    get type(): DeviceFileType {
        return this._type;
    }
}
