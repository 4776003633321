export class Jump {
    private readonly _id: number | null = null;
    private _number: number | null = null;
    private _timestamp: number | null = null;
    private _createdAt: number = 0;
    private _freeFallTime: number | null = null;
    private _exitAltitude: number | null = null;
    private _description: string | null = null;
    private _location: number | null = null;
    private _equipment: number | null = null;
    private _tags: Array<number> = [];
    private _fileContent: string | null = null;
    private _fileName: string | null = null;

    get id(): number | null {
        return this._id;
    }

    get number(): number | null {
        return this._number;
    }

    set number(value: number | null) {
        this._number = value;
    }

    get timestamp(): number | null {
        return this._timestamp;
    }

    set timestamp(value: number | null) {
        this._timestamp = value;
    }

    get createdAt(): number {
        return this._createdAt;
    }

    get freeFallTime(): number | null {
        return this._freeFallTime;
    }

    set freeFallTime(value: number | null) {
        this._freeFallTime = value;
    }

    get exitAltitude(): number | null {
        return this._exitAltitude;
    }

    set exitAltitude(value: number | null) {
        this._exitAltitude = value;
    }

    get description(): string | null {
        return this._description;
    }

    set description(value: string | null) {
        this._description = value;
    }

    get location(): number | null {
        return this._location;
    }

    set location(value: number | null) {
        this._location = value;
    }

    get equipment(): number | null {
        return this._equipment;
    }

    set equipment(value: number | null) {
        this._equipment = value;
    }

    get tags(): Array<number> {
        return this._tags || [];
    }

    set tags(value: Array<number>) {
        this._tags = value;
    }

    get fileContent(): string | null {
        return this._fileContent;
    }

    set fileContent(value: string | null) {
        this._fileContent = value;
    }

    get fileName(): string | null {
        return this._fileName;
    }

    set fileName(value: string | null) {
        this._fileName = value;
    }
}
