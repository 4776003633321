import {Fragment, useState} from "react";
import {Box, Button, Card, CardContent, FormControl, Grid, TextField, Typography} from "@mui/material";
import {asyncifyPreventDefault} from "../misc/ToolkitAsync";
import FormLoader from "../ui/component/FormLoader";
import {useAuth} from "../provider/AuthProvider";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

export default function LoginPage() {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const {authenticate} = useAuth();
    const {t} = useTranslation();
    const snackbar = useSnackbar();

    async function handleLogin() {
        try {
            if (!await authenticate(username, password)) {
                snackbar.enqueueSnackbar(t('login.failed'), {variant: 'error'})
            }
        } catch (e) {
            snackbar.enqueueSnackbar(t('message.network'), {variant: 'error'})
        }
    }

    return (
        <Fragment>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center"
                  style={{minHeight: '100vh'}}>
                <Box sx={{minWidth: 275, maxWidth: 300, minHeight: 1000}}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant='h5'>{t('login.welcome')}</Typography>
                            <Typography variant='subtitle1'>{t('login.welcome2')}</Typography>
                            <FormLoader submitFunction={handleLogin}>
                                <FormControl margin='normal' fullWidth>
                                    <TextField variant="standard" required id="username" label={t('login.username')} value={username}
                                               onChange={e => setUsername(e.target.value)}/>
                                </FormControl>
                                <FormControl margin='normal' fullWidth>
                                    <TextField variant="standard" required id="password" type='password' label={t('login.password')}
                                               value={password} onChange={e => setPassword(e.target.value)}/>
                                </FormControl>
                                <FormControl fullWidth margin='normal'>
                                    <Button type='submit' variant='contained'>{t('login.login')}</Button>
                                </FormControl>
                            </FormLoader>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Fragment>
    );
}
