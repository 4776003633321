import {useApi} from "../provider/ApiProvider";
import {GridColDef} from '@mui/x-data-grid';
import DataTable from "../ui/component/DataTable";
import {useTranslation} from "react-i18next";
import {LocationApi} from "../api/LocationApi";
import {Box, Fab} from "@mui/material";
import {DefaultFabStyle} from "../ui/component/Fab";
import AddIcon from "@mui/icons-material/Add";
import {useNavigate} from "react-router-dom";


export default function EquipmentsPage() {
    const {t} = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('equipment.name'),
            type: 'string',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        },
        {
            field: 'canopy',
            headerName: t('equipment.canopy'),
            type: 'string',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        },
        {
            field: 'canopySerial',
            headerName: t('equipment.canopySerial'),
            type: 'string',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        },
        {
            field: 'reserve',
            headerName: t('equipment.reserve'),
            type: 'string',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        },
        {
            field: 'reserveSerial',
            headerName: t('equipment.reserveSerial'),
            type: 'string',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        },
        {
            field: 'aad',
            headerName: t('equipment.aad'),
            type: 'string',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        },
        {
            field: 'aadSerial',
            headerName: t('equipment.aadSerial'),
            type: 'string',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        },
        {
            field: 'container',
            headerName: t('equipment.container'),
            type: 'string',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        },
        {
            field: 'containerSerial',
            headerName: t('equipment.containerSerial'),
            type: 'string',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        },
        {
            field: 'lastRepack',
            headerName: t('equipment.lastRepack'),
            type: 'string',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        },
        {
            field: 'active',
            headerName: t('equipment.active'),
            type: 'boolean',
            flex: 0.5,
            minWidth: 100,
            editable: false,
        }];

    const {equipmentApi} = useApi();

    return (<DataTable api={equipmentApi} initialState={{
        columns: {
            columnVisibilityModel: {
                name: true,
                canopy: true,
                canopySerial: false,
                reserve: true,
                reserveSerial: false,
                aad: true,
                aadSerial: false,
                container: true,
                containerSerial: false,
                active: true,
                lastRepack: true,
            },
        },
    }} columns={columns} editLocation={'/equipment/'} deletable={true}/>);
}
