import {Api} from "./Api";
import {GridPaginationModel} from "@mui/x-data-grid/models/gridPaginationProps";
import {useEffect, useRef, useState} from "react";
import {asyncify} from "../misc/ToolkitAsync";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";
import {Page} from "../model/http/Page";
import {Idable} from "../model/data/Idable";
import {AxiosResponse} from "axios";

export class CrudApi<Type extends Idable> {

    private readonly type: Type;
    protected api: Api;
    protected cache: Array<Type> | null = null;

    constructor(path: string, type: Type) {
        this.api = new Api(path);
        this.type = type;
    }


    public addErrorHandler(status: number, handler: (e: AxiosResponse) => Promise<any>): void {
        this.api.addErrorHandler(status, handler);
    }

    public async map(): Promise<Map<number, Type>> {
        const result = new Map<number, Type>();
        for (const item of await this.all()) {
            if (item.id !== null) {
                result.set(item.id, item);
            }
        }
        return result;
    }

    public async all(disableCache: boolean = false): Promise<Array<Type>> {
        if (disableCache || this.cache === null) {
            this.cache = await this.api.getObjects(this.type, '/all');
        }
        return this.cache;
    }

    public async list(gridPaginationModel?: GridPaginationModel, gridSortModel?: GridSortModel): Promise<Page<Type>> {
        return await this.api.getPage(this.type, gridPaginationModel, gridSortModel, '/list');
    }

    public async get(id: number): Promise<Type> {
        return await this.api.getObject(this.type, '/' + id);
    }

    public async delete(id: number): Promise<Type> {
        this.cache = null;
        return await this.api.delete({}, '/' + id);
    }

    public async save(data: Type): Promise<Type> {
        this.cache = null;
        const postData: any = {};
        for (const key of Object.keys(data as any)) {
            postData[key.replaceAll('_', '')] = (data as any)[key];
        }
        return await this.api.post(postData);
    }
}

interface PageInfo {
    totalRowCount?: number;
}

export const createCrudDataGrid = function<Type extends Idable>(crudApi: CrudApi<Type>) {
    const useQuery = function(gridPaginationModel: GridPaginationModel, gridSortModel: GridSortModel) {
        const gridPaginationModelRef = useRef(gridPaginationModel);
        const gridSortModelRef = useRef(gridSortModel);

        const [response, setResponse] = useState<{
            pageInfo: PageInfo;
            rows: Type[];
        }>({ pageInfo: {totalRowCount: 0}, rows: [] });
        const [isLoading, setIsLoading] = useState<boolean>(true);

        const loadData = async function() {
            gridPaginationModelRef.current = gridPaginationModel;
            gridSortModelRef.current = gridSortModel;
            setIsLoading(true);
            const data = await crudApi.list(gridPaginationModel, gridSortModel);

            const newResponse = {pageInfo: {totalRowCount: data.total}, rows: data.data};
            setResponse(newResponse);
            setIsLoading(false);
        }

        useEffect(asyncify(loadData), [gridPaginationModel, gridSortModel]);
        const effectShouldStart = gridPaginationModelRef.current !== gridPaginationModel
            || gridSortModelRef.current !== gridSortModel;
        return {
            loadData,
            isLoading: isLoading || effectShouldStart,
            ...response,
        };
    }
    return {useQuery};
}
