import {Api} from "./Api";

export class MeApi extends Api {
    constructor() {
        super('/me');
    }

    public async updatePassword(oldPassword: string, newPassword: string): Promise<boolean> {
        return (await this.post({oldPassword, newPassword})) as boolean;
    }
}
