import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ProtrackJumpFile} from "../../model/data/files/ProtrackJumpFile";
import {useTranslation} from "react-i18next";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {Line} from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const backgroundColor = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)',
];
const borderColor = [
    'rgba(255, 99, 132, 1)',
    'rgb(0,152,255)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
];

export default function ProtrackDataPlotComponent({protrackJumpFile}: {protrackJumpFile: ProtrackJumpFile}) {
    const {t} = useTranslation();
    const [chartData, setChartData] = useState<any>({labels: [], datasets: []});

    function pressureToFeet(point: number, groundLevel: number) {
        return 145366.45 * (1-Math.pow((point / 10) / 1013.25, 0.190284));
    }

    function feetToM(feet: number) {
        return feet / 3.28084;
    }

    useEffect(() => {
        const labels: Array<number> = [];
        const points = protrackJumpFile.points;
        for (let i = 0; i < points.length; ++i) {
            labels.push(i / 4);
        }
        const datasets: Array<any> = [];

        const zero = pressureToFeet(protrackJumpFile.groundLevel, protrackJumpFile.groundLevel);

        const altitude = points
            .map(point => pressureToFeet(point, protrackJumpFile.groundLevel) - zero);

        const speed = [0];
        for (let i = 1; i < altitude.length; i++) {
            let count = 0;
            let dataPoint = 0;
            const space = Math.min(8, i, altitude.length - i);
            for (let j = Math.max(i - space, 1); j < i + space; j++) {
                dataPoint += feetToM((altitude[j - 1] - altitude[j]) * 4) * 3.6;
                ++count;
            }
            speed.push(dataPoint / count);
        }

        datasets.push({
            label: t('dataPlot.altitudeLabel'),
            data: altitude,
            borderColor: borderColor[1],
            backgroundColor: backgroundColor[1],
            yAxisID: "y-axis-alt",
        });

        datasets.push({
            label: t('dataPlot.speedLabel'),
            data: speed,
            borderColor: borderColor[0],
            backgroundColor: backgroundColor[0],
            yAxisID: "y-axis-speed",
        });

        const data = {
            labels: labels,
            datasets: datasets,
        };
        setChartData(data);
    }, [protrackJumpFile]);

    const options2 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: t('dataPlot.graphName'),
            },
        },
    };

    return (<Box>
        <Line options={options2} data={chartData}/>
    </Box>);
}
