import {Role} from "../data/User";

export class Me {
    private readonly _username: string | null;
    private readonly _userRole: Role | null;

    constructor(username: string | null, userRole: Role | null) {
        this._username = username;
        this._userRole = userRole;
    }

    get username(): string | null {
        return this._username;
    }

    get userRole(): Role | null {
        return this._userRole;
    }
}
