import {Year} from "./Year";
import {StatEquipment} from "./StatEquipment";
import {StatLocation} from "./StatLocation";
import {StatTags} from "./StatTags";

export class Stats {
    private readonly _years: Array<Year> = [];
    private readonly _equipments: Array<StatEquipment> = [];
    private readonly _locations: Array<StatLocation> = [];
    private readonly _tags: Array<StatTags> = [];

    constructor(years: Array<Year>, equipments: Array<StatEquipment>, locations: Array<StatLocation>, tags: Array<StatTags>) {
        this._years = years;
        this._equipments = equipments;
        this._locations = locations;
        this._tags = tags;
    }

    get years(): Array<Year> {
        return this._years;
    }

    get equipments(): Array<StatEquipment> {
        return this._equipments;
    }

    get locations(): Array<StatLocation> {
        return this._locations;
    }

    get tags(): Array<StatTags> {
        return this._tags;
    }
}
