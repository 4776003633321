import {Api} from "./Api";
import {Stats} from "../model/data/stats/Stats";
import {StatTags} from "../model/data/stats/StatTags";
import {StatLocation} from "../model/data/stats/StatLocation";
import {StatEquipment} from "../model/data/stats/StatEquipment";
import {Year} from "../model/data/stats/Year";

export class StatApi extends Api {
    constructor() {
        super('/stats');
    }

    public async getStats(): Promise<Stats> {
        const statsData: any = await this.get();
        return new Stats(await this.handleObjectsRequest(Year.prototype, statsData['years']),
            await this.handleObjectsRequest(StatEquipment.prototype, statsData['equipments']),
            await this.handleObjectsRequest(StatLocation.prototype, statsData['locations']),
            await this.handleObjectsRequest(StatTags.prototype, statsData['tags']));
    }
}
