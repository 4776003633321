import {CrudApi} from "./CrudApi";
import {LogbookFile} from "../model/data/files/LogbookFile";
import {DeviceFileType} from "../model/data/DeviceFile";
import {ProtrackJumpFile} from "../model/data/files/ProtrackJumpFile";
import {FlySightFileJump} from "../model/data/files/FlySightFileJump";

export class LogbookFileApi extends CrudApi<LogbookFile> {
    constructor() {
        super('/device_file', LogbookFile.prototype);
        this.api.typeDefiner = (<Type>(data: any) => {
            if (data.type === DeviceFileType.PROTRACK_II_DATA) {
                return ProtrackJumpFile.prototype as Type;
            } else if (data.type === DeviceFileType.FLYSIGHT_DATA) {
                return FlySightFileJump.prototype as Type;
            }
            return LogbookFile.prototype as Type;
        });
    }
}
