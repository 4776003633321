import {Api} from "./Api";
import {Me} from "../model/auth/Me";

export class AuthApi extends Api {
    constructor() {
        super('/auth');
    }

    public async me(): Promise<Me> {
        return await this.getObject(Me.prototype, '/me');
    }

    public async login(username: string, password: string): Promise<Me> {
        return await this.postObject(Me.prototype, {username, password}, "/login");
    }

    public async logout(): Promise<void> {
        await this.post({}, "/logout");
    }
}
