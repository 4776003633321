import {SyntheticEvent} from "react";

export function asyncify<A extends any[], R extends any>(func: ((...a: A) => Promise<R>)) {
    return function(...args: A): void {
        func(...args).then(() => {}).catch(console.error);
    }
}

export function asyncifyPreventDefault<E extends SyntheticEvent, A extends any[], R extends any>(func: ((...a: A) => Promise<R>)) {
    return function(e: E, ...args: A): void {
        e.preventDefault();
        func(...args).then(() => {}).catch(console.error);
    }
}