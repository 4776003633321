import {useAuth} from "../provider/AuthProvider";
import {Navigate} from "react-router-dom";
import {Box} from "@mui/material";

export function LoggedoutRoute({children}: { children: string | JSX.Element | JSX.Element[] }) {
    const {user} = useAuth();

    if (user) {
        return (<Navigate to="/"/>);
    }
    return (<Box>{children}</Box>);
}
