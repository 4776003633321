import {useApi} from "../provider/ApiProvider";
import {useTranslation} from "react-i18next";
import {Box, Button, FormControl, MenuItem, Select, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import EditComponent from "../ui/component/EditComponent";
import {Role, User} from "../model/data/User";
import {useAuth} from "../provider/AuthProvider";

export default function UserEditPage() {
    const {t} = useTranslation();
    const {hasRole} = useAuth();
    const {userApi} = useApi();
    const [user, setUser] = useState<User | null>(null);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordCheck, setPasswordCheck] = useState<string>('');
    const [role, setRole] = useState<Role>(Role.USER);

    useEffect(() => {
        if (user) {
            setUsername(user.username);
            setRole(user.role);
        }
    }, [user]);

    function getUser(): User {
        const result = user || new User();
        result.username = username;
        result.role = role;
        if (password !== passwordCheck) {
            throw new Error("Failed to match passwords");
        }
        if (password) {
            result.password = password;
        }
        return result;
    }

    return (<EditComponent api={userApi} setSource={setUser} getSavable={getUser} successSavePage='/users'>
        <FormControl margin='normal' fullWidth>
            <TextField variant="standard" id="label" required label={t('users.username')}
                       value={username} onChange={e => setUsername(e.target.value)}/>
        </FormControl>
        <FormControl margin='normal' fullWidth>
            <TextField variant="standard" id="password" label={t('users.password')}
                       type="password"
                       value={password} onChange={e => setPassword(e.target.value)}/>
        </FormControl>
        <FormControl margin='normal' fullWidth>
            <TextField variant="standard" id="password_check" label={t('users.password_check')}
                       type="password"
                       value={passwordCheck} onChange={e => setPasswordCheck(e.target.value)}/>
        </FormControl>
        {hasRole([Role.ADMIN])?(
            <FormControl margin='normal' fullWidth>
                <Select variant='standard' value={role}
                        onChange={x => setRole(x.target.value === null ? Role.USER : parseInt('' + x.target.value))}>
                    <MenuItem value={Role.USER}>{t('users.role.user')}</MenuItem>
                    <MenuItem value={Role.RESELLER}>{t('users.role.reseller')}</MenuItem>
                    <MenuItem value={Role.ADMIN}>{t('users.role.admin')}</MenuItem>
                </Select>
            </FormControl>
        ):<Box></Box>}
        <FormControl margin='normal'>
            <Button type='submit' variant='contained'>
                {user?.id == null ? t('form.create'):t('form.update')}
            </Button>
        </FormControl>
    </EditComponent>);
}
