import {useApi} from "../provider/ApiProvider";
import {useTranslation} from "react-i18next";
import {Button, FormControl, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {Location} from "../model/data/Location";
import EditComponent from "../ui/component/EditComponent";

export default function LocationEditPage() {
    const {t} = useTranslation();
    const {locationApi} = useApi();
    const [location, setLocation] = useState<Location | null>(null);
    const [name, setName] = useState('');
    const [coordinate, setCoordinate] = useState('');

    useEffect(() => {
        if (location) {
            setName(location.name);
            setCoordinate(location.coordinate || '');
        }
    }, [location]);

    function getLocation(): Location {
        let result = location || new Location();
        result.name = name;
        return result;
    }

    return (<EditComponent api={locationApi} setSource={setLocation} getSavable={getLocation} successSavePage='/locations'>
        <FormControl margin='normal' fullWidth>
            <TextField variant="standard" required id="name" label={t('location.name')}
                       value={name} onChange={e => setName(e.target.value)}/>
        </FormControl>
        <FormControl margin='normal' fullWidth>
            <TextField variant="standard" id="coordinate" label={t('location.coordinate')}
                       value={coordinate} onChange={e => setCoordinate(e.target.value)}/>
        </FormControl>
        <FormControl margin='normal'>
            <Button type='submit' variant='contained'>
                {location?.id == null ? t('form.create'):t('form.update')}
            </Button>
        </FormControl>
    </EditComponent>);
}
