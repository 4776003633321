import {Alert, Box, Button, Grid} from "@mui/material";
import {asyncify, asyncifyPreventDefault} from "../../misc/ToolkitAsync";
import {useState} from "react";

export default function FormLoader({submitFunction, children}: {submitFunction: (() => Promise<void>),
        children: string | JSX.Element | JSX.Element[]}) {
    const [loading, setLoading] = useState(false);

    async function submitHandler() {
        setLoading(true);
        try {
            await submitFunction();
        } finally {
            setLoading(false);
        }
    }

    return (
        <fieldset style={{border: "none", margin: 0, padding:0}} disabled={loading}>
            <form onSubmit={asyncifyPreventDefault(submitHandler)}>
                {children}
            </form>
        </fieldset>
        );
}
