import {useTranslation} from "react-i18next";
import {GridColDef} from "@mui/x-data-grid";
import {useApi} from "../../provider/ApiProvider";
import DataTable from "../../ui/component/DataTable";
import {ToolkitTime} from "../../misc/ToolkitTime";
import {useEffect, useState} from "react";
import {asyncify} from "../../misc/ToolkitAsync";
import {Location} from "../../model/data/Location";
import {Equipment} from "../../model/data/Equipment";
import {Jump} from "../../model/data/Jump";
import UploadIcon from '@mui/icons-material/Upload';
import {
    Box,
    Button,
    Card,
    CardContent,
    Fab,
    FormControl,
    InputLabel,
    SpeedDial, SpeedDialAction,
    SpeedDialIcon,
    Typography
} from "@mui/material";
import GenericTagsComponent from "../../ui/component/GenericTagsComponent";
import {JumpTag} from "../../model/data/JumpTag";
import FormLoader from "../../ui/component/FormLoader";
import GenericSelectComponent from "../../ui/component/GenericSelectComponent";
import SelectTagsComponent from "../../ui/component/SelectTagsComponent";
import {DefaultFabStyle} from "../../ui/component/Fab";
import AddIcon from "@mui/icons-material/Add";
import {useNavigate} from "react-router-dom";

export default function JumpPage() {
    const {t} = useTranslation();
    const [locationsMap, setLocationsMap] = useState<Map<number, Location> | null>(null);
    const [equipmentMap, setEquipmentMap] = useState<Map<number, Equipment> | null>(null);
    const [tagsMap, setTagsMap] = useState<Map<number, JumpTag> | null>(null);
    const [selectedJumps, setSelectedJumps] = useState<Array<Jump>>([]);


    const [location, setLocation] = useState<number | null>(null);
    const [equipment, setEquipment] = useState<number | null>(null);
    const [updater, setUpdater] = useState<number>(0);
    const [selectedTags, setSelectedTags] = useState<Array<number>>([]);

    const navigate = useNavigate();

    const defaultCol: Partial<GridColDef> = {
        type: 'number',
        flex: 0.1,
        minWidth: 50,
        align: 'left',
        headerAlign: 'left',
        editable: false,
    };

    const columns: GridColDef[] = [
        {
            ...defaultCol,
            field: 'number',
            headerName: t('jump.number'),
            flex: 0.05,
            minWidth: 60,
        },
        {
            ...defaultCol,
            field: 'timestamp',
            headerName: t('jump.timestamp'),
            valueGetter: (params) => {
                return ToolkitTime.dynamicFormatDateTime(params.row.timestamp);
            },
            minWidth: 170,
        },
        {
            ...defaultCol,
            flex: 0.07,
            field: 'freeFallTime',
            headerName: t('jump.freeFallTime'),
            minWidth: 80,
        },
        {
            ...defaultCol,
            flex: 0.07,
            field: 'exitAltitude',
            headerName: t('jump.exitAltitude'),
            minWidth: 80,
        },
        {
            field: 'location',
            headerName: t('jump.location'),
            valueGetter: (params) => {
                return locationsMap ? locationsMap.get(params.row.location)?.name : '-';
            },
            flex: 0.1,
            minWidth: 100,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            sortable: false,
        },
        {
            field: 'equipment',
            headerName: t('jump.equipment'),
            valueGetter: (params) => {
                return equipmentMap ? equipmentMap.get(params.row.equipment)?.name : '-';
            },
            flex: 0.1,
            minWidth: 100,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            sortable: false,
        },
        {
            field: 'tags',
            headerName: t('jump.tags'),
            renderCell: (params) => {
                return <GenericTagsComponent values={tagsMap} ids={params.row.tags || []}/>;
            },
            flex: 0.1,
            minWidth: 200,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            sortable: false,
        },
    ];

    const {jumpApi, locationApi, equipmentApi, jumpTagApi} = useApi();


    async function loadData() {
        setLocationsMap(await locationApi.map());
        setTagsMap(await jumpTagApi.map());
        setEquipmentMap(await equipmentApi.map());
    }

    async function updateJumps() {
        for (const jump of selectedJumps) {
            if (location != null) {
                jump.location = location;
            }
            if (equipment != null) {
                jump.equipment = equipment;
            }
            if (location != null || equipment != null) {
                await jumpApi.save(jump);
            }
            if (selectedTags != null && selectedTags.length > 0) {
                const org = jump.tags;
                const toDelete = org.slice().filter(x => !selectedTags.includes(x));
                let toAdd = selectedTags.slice().filter(x => !org.includes(x));
                await jumpApi.updateTags(jump, toAdd, toDelete);
            }
            await loadData();
            setUpdater(updater + 1);
        }
    }

    useEffect(asyncify(loadData), []);


    return (<Box>
        <DataTable api={jumpApi} defaultSort={'number'} defaultPageSize={20} columns={columns}
                   setSelected={setSelectedJumps} updater={updater}
                   viewLocation={'/jump/view/'}/>
        {selectedJumps.length > 0 ? (<Card variant="outlined" style={{marginTop: 30}}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">Modify selected</Typography>
                <FormLoader submitFunction={updateJumps}>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel id="select-location-label">{t('jump.location')}</InputLabel>
                        <GenericSelectComponent labelId='select-location-label'
                                                id={location} setId={setLocation} api={locationApi} />
                    </FormControl>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel id="select-equipment-label">{t('jump.equipment')}</InputLabel>
                        <GenericSelectComponent labelId='select-equipment-label'
                                                filter={e => e.active}
                                                id={equipment} setId={setEquipment} api={equipmentApi} />
                    </FormControl>
                    <FormControl margin='normal' fullWidth>
                        <SelectTagsComponent selection={selectedTags} setSelection={setSelectedTags} />
                    </FormControl>
                    <FormControl margin='normal'>
                        <Button type='submit' variant='contained'>
                            {t('form.update')}
                        </Button>
                    </FormControl>
                </FormLoader>
            </CardContent>
        </Card>): <Box />}
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{position: 'absolute', bottom: 16, right: 16}}
            icon={<SpeedDialIcon/>}>
            <SpeedDialAction onClick={x => navigate('/jump/new')} icon=<AddIcon/> tooltipTitle={"Add"}/>
            <SpeedDialAction onClick={x => navigate('/jump/upload')} icon=<UploadIcon/> tooltipTitle={"Upload"}/>
        </SpeedDial>
    </Box>);
}
