import * as React from "react";
import {Api} from "../api/Api";
import {useAuth} from "./AuthProvider";
import {AxiosResponse} from "axios";
import {JumpTagApi} from "../api/JumpTagApi";
import {LocationApi} from "../api/LocationApi";
import {EquipmentApi} from "../api/EquipmentApi";
import {JumpApi} from "../api/JumpApi";
import {UserApi} from "../api/UserApi";
import {MeApi} from "../api/MeApi";
import {StatApi} from "../api/StatApi";
import {LogbookFileApi} from "../api/LogbookFileApi";

type ApiSet = {
    meApi: MeApi;
    jumpTagApi: JumpTagApi;
    equipmentApi: EquipmentApi;
    locationApi: LocationApi;
    jumpApi: JumpApi;
    userApi: UserApi;
    statsApi: StatApi;
    logbookFileApi: LogbookFileApi;
};

const ApiContext = React.createContext<ApiSet | null>(null);

export function useApi(): ApiSet  {
    const result = React.useContext(ApiContext);
    if (!result) {
        throw new Error('Called at the wrong time');
    }
    return result;
}

const apiSet: ApiSet = {meApi: new MeApi(), jumpTagApi: new JumpTagApi(), locationApi: new LocationApi(),
    equipmentApi: new EquipmentApi(), jumpApi: new JumpApi(), userApi: new UserApi(),
    statsApi: new StatApi(), logbookFileApi: new LogbookFileApi()};

export default function ApiProvider({children}: { children: string | JSX.Element | JSX.Element[] }) {
    const auth = useAuth();

    async function authFailureHandler(e: AxiosResponse): Promise<undefined> {
        await auth.reauthenticate();
        return undefined;
    }

    for (const api of [apiSet.meApi, apiSet.userApi, apiSet.equipmentApi, apiSet.locationApi, apiSet.jumpTagApi,
            apiSet.jumpApi, apiSet.logbookFileApi]) {
        api.addErrorHandler(401, authFailureHandler);
        api.addErrorHandler(403, authFailureHandler);
    }

    return (<ApiContext.Provider value={apiSet}>
        {children}
    </ApiContext.Provider>);
}
