import {ListItemButton} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {SyntheticEvent} from "react";
import {ListItemButtonBaseProps} from "@mui/material/ListItemButton/ListItemButton";

export default function ListItemButtonLink(props: ListItemButtonBaseProps & {to: string, children: string | JSX.Element | JSX.Element[]}) {
    const navigate = useNavigate();

    function route(e: SyntheticEvent) {
        e.preventDefault();
        navigate(props.to);
    }

    return <ListItemButton {...props} onClick={route}  href={props.to}>{props.children}</ListItemButton>
}
