import {useApi} from "../provider/ApiProvider";
import {GridColDef} from '@mui/x-data-grid';
import DataTable from "../ui/component/DataTable";
import {useTranslation} from "react-i18next";


export default function UsersPage() {
    const {t} = useTranslation();

    const columns: GridColDef[] = [
        {
            field: 'username',
            headerName: t('users.username'),
            type: 'string',
            flex: 0.5,
            minWidth: 200,
            editable: false,
        },
        {
            field: 'owner',
            headerName: t('location.owner'),
            type: 'string',
            flex: 0.5,
            minWidth: 200,
            editable: false,
        },
        {
            field: 'role',
            headerName: t('location.role'),
            type: 'string',
            flex: 0.5,
            minWidth: 200,
            editable: false,
        }
    ];

    const {userApi} = useApi();

    return (<DataTable api={userApi} columns={columns} editLocation={'/user/'}/>);
}
