import {Card, CardContent, CardHeader, FormControl} from "@mui/material";
import {useApi} from "../provider/ApiProvider";
import LoadReloadComponent from "../ui/component/LoadReloadComponent";
import {useEffect, useState} from "react";
import {Stats} from "../model/data/stats/Stats";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Year} from "../model/data/stats/Year";
import {StatTags} from "../model/data/stats/StatTags";
import GenericChipComponent from "../ui/component/GenericTagsComponent";
import {JumpTag} from "../model/data/JumpTag";
import Link from "../ui/component/Link";
import SelectTagsComponent from "../ui/component/SelectTagsComponent";
import {useTranslation} from "react-i18next";

export default function StatsPage() {

    const [tagsMap, setTagsMap] = useState<Map<number, JumpTag> | null>(null);
    const [stats, setStats] = useState<Stats | null>(null);
    const [tagsData, setTagsData] = useState<Array<StatTags>>([]);
    const [selectedTags, setSelectedTags] = useState<Array<number>>([]);
    const {t} = useTranslation();

    const {statsApi, jumpTagApi} = useApi();

    async function loadData() {
        setStats(await statsApi.getStats());
        setTagsMap(await jumpTagApi.map());
    }

    useEffect(() => {
        if (selectedTags.length > 0) {
            const newStats: Map<string, StatTags> = new Map();
            for (const tag of stats?.tags || []) {
                const tagIds = tag.tags.filter(item => selectedTags.includes(item)).sort();
                const tagString = tagIds.join(',');
                let tagCount = newStats.get(tagString);
                if (tagCount == null) {
                    tagCount = new StatTags();
                    tagCount.tags = tagIds;
                    newStats.set(tagString, tagCount);
                }
                tagCount.count += tag.count;
            }
            setTagsData(Array.from(newStats.values()));
        } else {
            setTagsData(stats?.tags || []);
        }
    }, [selectedTags, stats]);

    const yearColumns: GridColDef[] = [
        {field: 'year', headerName: t('stat.year'), flex: 0.1,},
        {field: 'count', headerName: t('stat.count'), flex: 0.1,}];

    const locationColumns: GridColDef[] = [
        {
            field: 'name', headerName: t('stat.name'), flex: 0.1,
            renderCell: (params) => {
                return <Link to={'/location/' + params.row.id}>{params.row.name}</Link>;
            }
        },
        {field: 'count', headerName: t('stat.count'), flex: 0.1,}];

    const equipmentColumns: GridColDef[] = [
        {
            field: 'name', headerName: t('stat.name'), flex: 0.1,
            renderCell: (params) => {
                return <Link to={'/equipment/' + params.row.id}>{params.row.name}</Link>;
            }
        },
        {field: 'count', headerName: t('stat.count'), flex: 0.1,}];

    const tagsColumns: GridColDef[] = [
        {
            field: 'tags', headerName: t('stat.tags'), flex: 0.1,
            sortable: false,
            renderCell: (params) => {
                return <GenericChipComponent values={tagsMap} ids={params.row.tags || []}/>;
            }
        },
        {field: 'count', headerName: t('stat.count'), flex: 0.1,}];

    return (
        <LoadReloadComponent reload={loadData}>
            <Card variant="outlined">
                <CardContent>
                    <DataGrid
                        rows={stats?.years || []}
                        columns={yearColumns}
                        getRowId={(year: Year) => year.year}
                        initialState={{
                            sorting: {
                                sortModel: [{field: 'year', sort: 'desc'}]
                            },
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick />
                </CardContent>
            </Card>
            <Card variant="outlined">
                <CardContent>
                    <DataGrid
                        rows={stats?.locations || []}
                        columns={locationColumns}
                        initialState={{
                            sorting: {
                                sortModel: [{field: 'count', sort: 'desc'}]
                            },
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick />
                </CardContent>
            </Card>
            <Card variant="outlined">
                <CardContent>
                    <DataGrid
                        rows={stats?.equipments || []}
                        columns={equipmentColumns}
                        initialState={{
                            sorting: {
                                sortModel: [{field: 'count', sort: 'desc'}]
                            },
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick />
                </CardContent>
            </Card>
            <Card variant="outlined">
                <CardContent>
                    <DataGrid
                        rows={tagsData}
                        columns={tagsColumns}
                        getRowId={(statTags: StatTags) => statTags.tags.join(',')}
                        initialState={{
                            sorting: {
                                sortModel: [{field: 'count', sort: 'desc'}]
                            },
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        disableColumnFilter={true}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick />
                    <FormControl margin='normal' fullWidth>
                        <SelectTagsComponent selection={selectedTags} setSelection={setSelectedTags} />
                    </FormControl>
                </CardContent>
            </Card>
        </LoadReloadComponent>);
}
