import {MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import {asyncify} from "../../misc/ToolkitAsync";
import {CrudApi} from "../../api/CrudApi";
import {IdableAndNameable} from "../../model/data/IdableAndNameable";
import LoadReloadComponent from "./LoadReloadComponent";

export default function GenericSelectComponent<Type extends IdableAndNameable>({id, setId, api, labelId, filter}: {id: number | null,
        setId: (id: number | null) => void, api: CrudApi<Type>, labelId?: string, filter?: (item: Type) => boolean}) {
    const [items, setItems] = useState<Array<Type>>([]);

    async function loadData() {
        let items = await api.all();
        if (filter) {
            items = items.filter(e => id === e.id || filter(e));
        }
        setItems(items);
    }

    return (<LoadReloadComponent reload={loadData}>
        <Select labelId={labelId} variant='standard' value={id || ''}
                onChange={x => setId(x.target.value === '' ? null : parseInt('' + x.target.value))}>
            <MenuItem value=''>-</MenuItem>
            {items.map((item: Type) => <MenuItem key={item.id} value={'' + item.id}>{item.name}</MenuItem>)}
        </Select></LoadReloadComponent>);
}
