import {
    Box,
    Card,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {DeviceFile, DeviceFileType} from "../../model/data/DeviceFile";
import LoadReloadComponent from "./LoadReloadComponent";
import {useApi} from "../../provider/ApiProvider";
import React, {useState} from "react";
import {LogbookFile} from "../../model/data/files/LogbookFile";
import {ProtrackJumpFile} from "../../model/data/files/ProtrackJumpFile";
import {useTranslation} from "react-i18next";
import ProtrackDataPlotComponent from "./ProtrackDataPlotComponent";
import {ToolkitTime} from "../../misc/ToolkitTime";
import {FlySightFileJump} from "../../model/data/files/FlySightFileJump";
import FlySightJumpDataPlotComponent from "./FlySightJumpDataPlotComponent";

export default function FlySightJumpFileComponent({deviceFile}: {deviceFile: DeviceFile}) {
    const {t} = useTranslation();
    const {logbookFileApi} = useApi();
    const [flySightFileJump, setFlySightFileJump] = useState<FlySightFileJump | null>(null);

    async function loadData() {
        console.log('woop woop');
        if (deviceFile.id) {
            const file = await logbookFileApi.get(deviceFile.id);
            console.log('file:', file);
            if (file.type === DeviceFileType.FLYSIGHT_DATA) {
                setFlySightFileJump(file as FlySightFileJump);
            }
        }
    }

    return (<LoadReloadComponent reload={loadData}>
            <Card style={{marginTop: 30}}>
                <CardContent>
                    <Typography variant="h6" noWrap component="div"  sx={{ flexGrow: 1 }}>
                        {deviceFile.name}
                    </Typography>
                    {flySightFileJump ? (
                        <Box>
                            <FlySightJumpDataPlotComponent flysightFileJump={flySightFileJump} />
                        </Box>
                    ) : <Box />}
                </CardContent>
            </Card>
    </LoadReloadComponent>);
}
