import {LinkProps} from "@mui/material/Link/Link";
import {Link as MuiLink} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {SyntheticEvent} from "react";

export default function Link(props: LinkProps & {to: string, children: string | JSX.Element}) {
    const navigate = useNavigate();

    function route(e: SyntheticEvent) {
        e.preventDefault();
        navigate(props.to);
    }

    return <MuiLink {...props} onClick={route}  href={props.to}>{props.children}</MuiLink>
}