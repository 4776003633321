import {useApi} from "../../provider/ApiProvider";
import {useTranslation} from "react-i18next";
import {Button, Card, CardContent, FormControl, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import EditComponent from "../../ui/component/EditComponent";
import {Jump} from "../../model/data/Jump";
import GenericSelectComponent from "../../ui/component/GenericSelectComponent";
import SelectTagsComponent from "../../ui/component/SelectTagsComponent";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment, {Moment} from "moment";
import {MuiFileInput} from "mui-file-input";
import {DeviceFile} from "../../model/data/DeviceFile";
import {asyncify} from "../../misc/ToolkitAsync";
import FormLoader from "../../ui/component/FormLoader";
import {Api} from "../../api/Api";

export default function ImportPage() {
    const {t} = useTranslation();
    const [file, setFile] = useState<File | null>(null)
    const api = new Api('/import');

    async function importFile() {
        if (file) {
            var formData = new FormData();
            formData.append('file', file);
            api.post(formData, '', {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
    }

    return (<Card variant="outlined">
        <CardContent>
            <FormLoader submitFunction={importFile}>
                <FormControl margin='normal' fullWidth>
                    <MuiFileInput value={file} onChange={setFile} label='Log file' />
                </FormControl>
                <FormControl margin='normal'>
                    <Button type='submit' variant='contained'>
                        {t('form.upload')}
                    </Button>
                </FormControl>
            </FormLoader>
        </CardContent>
    </Card>);
}
