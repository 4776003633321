import {useApi} from "../provider/ApiProvider";
import {GridColDef} from '@mui/x-data-grid';
import DataTable from "../ui/component/DataTable";
import {useTranslation} from "react-i18next";
import {Box, Button, Card, CardContent, Fab, FormControl, TextField, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {asyncify} from "../misc/ToolkitAsync";
import LoadReloadComponent from "../ui/component/LoadReloadComponent";
import {Location} from "../model/data/Location";
import FormLoader from "../ui/component/FormLoader";
import {useSnackbar} from "notistack";
import {Equipment} from "../model/data/Equipment";
import EditComponent from "../ui/component/EditComponent";
import {useAuth} from "../provider/AuthProvider";

export default function ProfileEditPage() {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const {meApi} = useApi();
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordCheck, setNewPasswordCheck] = useState('');
    const [oldPassword, setOldPassword] = useState('');

    async function updatePassword() {
        if (newPassword === newPasswordCheck) {
            if (await meApi.updatePassword(oldPassword, newPassword)) {
                enqueueSnackbar(t('profile.success'));
                setNewPassword('');
                setNewPasswordCheck('');
                setOldPassword('');
            } else {
                enqueueSnackbar(t('profile.success'));
            }
        } else {
        //    TODO form validation
        }
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <FormLoader submitFunction={updatePassword}>
                    <FormControl margin='normal' fullWidth>
                        <TextField variant="standard" required id="password" label={t('user.oldPassword')}
                                   type='password'
                                   value={oldPassword} onChange={e => setOldPassword(e.target.value)}/>
                    </FormControl>
                    <FormControl margin='normal' fullWidth>
                        <TextField variant="standard" required id="newPassword" label={t('user.newPassword')}
                                   type='password'
                                   value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
                    </FormControl>
                    <FormControl margin='normal' fullWidth>
                        <TextField variant="standard" required id="newPasswordCheck" label={t('user.newPasswordCheck')}
                                   type='password'
                                   value={newPasswordCheck} onChange={e => setNewPasswordCheck(e.target.value)}/>
                    </FormControl>
                    <FormControl margin='normal'>
                        <Button type='submit' variant='contained'>{t('form.save')}</Button>
                    </FormControl>
                </FormLoader>
            </CardContent>
        </Card>);
}
