import {LogbookFile} from "./LogbookFile";

export class ProtrackJumpFile extends LogbookFile {
    private _groundLevel: number =  0;
    private _temperature: number =  0;
    private _points: Array<number> = [];
    private _fileVersion: string =  '';
    private _deviceNumber: string =  '';
    private _firmwareVersion: string =  '';
    private _serialNumber: string =  '';
    private _jumpNumber: number =  0;
    private _jumpTimestamp: number =  0;
    private _exitAltitude: number =  0;
    private _deployAltitude: number =  0;
    private _freefallTime: number =  0;
    private _tasAverageSpeed: number =  0;
    private _tasMaxSpeed: number =  0;
    private _tasMinSpeed: number =  0;
    private _tasFirstHalfSpeed: number =  0;
    private _tasSecondHalfSpeed: number =  0;
    private _sasAverageSpeed: number =  0;
    private _sasMaxSpeed: number =  0;
    private _sasMinSpeed: number =  0;
    private _sasFirstHalfSpeed: number =  0;
    private _sasSecondHalfSpeed: number =  0;
    private _driveType: number =  0;
    private _lookupLine: Array<number> = [];
    private _rfu: Array<number> = [];

    get groundLevel(): number {
        return this._groundLevel;
    }

    get temperature(): number {
        return this._temperature;
    }

    get points(): Array<number> {
        return this._points;
    }

    get fileVersion(): string {
        return this._fileVersion;
    }

    get deviceNumber(): string {
        return this._deviceNumber;
    }

    get firmwareVersion(): string {
        return this._firmwareVersion;
    }

    get serialNumber(): string {
        return this._serialNumber;
    }

    get jumpNumber(): number {
        return this._jumpNumber;
    }

    get jumpTimestamp(): number {
        return this._jumpTimestamp;
    }

    get exitAltitude(): number {
        return this._exitAltitude;
    }

    get deployAltitude(): number {
        return this._deployAltitude;
    }

    get freefallTime(): number {
        return this._freefallTime;
    }

    get tasAverageSpeed(): number {
        return this._tasAverageSpeed;
    }

    get tasMaxSpeed(): number {
        return this._tasMaxSpeed;
    }

    get tasMinSpeed(): number {
        return this._tasMinSpeed;
    }

    get tasFirstHalfSpeed(): number {
        return this._tasFirstHalfSpeed;
    }

    get tasSecondHalfSpeed(): number {
        return this._tasSecondHalfSpeed;
    }

    get sasAverageSpeed(): number {
        return this._sasAverageSpeed;
    }

    get sasMaxSpeed(): number {
        return this._sasMaxSpeed;
    }

    get sasMinSpeed(): number {
        return this._sasMinSpeed;
    }

    get sasFirstHalfSpeed(): number {
        return this._sasFirstHalfSpeed;
    }

    get sasSecondHalfSpeed(): number {
        return this._sasSecondHalfSpeed;
    }

    get driveType(): number {
        return this._driveType;
    }

    get lookupLine(): Array<number> {
        return this._lookupLine;
    }

    get rfu(): Array<number> {
        return this._rfu;
    }
}
