import {Box, Chip, ListItem, MenuItem, Select} from "@mui/material";
import {useEffect, useState} from "react";
import {asyncify} from "../../misc/ToolkitAsync";
import {CrudApi} from "../../api/CrudApi";
import {IdableAndNameable} from "../../model/data/IdableAndNameable";

export default function GenericTagsComponent<Type extends IdableAndNameable>({ids, values}: {ids: Array<number>,
        values: Map<number, Type> | null}) {


    return (<Box>{ids.map(id => values?.get(id)?.name)
        .filter(item => item !== undefined)
        .map((name) => (<Chip  key={name} label={name}/>))}</Box>);
}
