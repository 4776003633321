import {useApi} from "../../provider/ApiProvider";
import {useTranslation} from "react-i18next";
import {Card, CardContent, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {Fragment, useState} from "react";
import {Jump} from "../../model/data/Jump";
import {DeviceFile, DeviceFileType} from "../../model/data/DeviceFile";
import LoadReloadComponent from "../../ui/component/LoadReloadComponent";
import {useNavigate, useParams} from "react-router-dom";
import {ToolkitTime} from "../../misc/ToolkitTime";
import {Location} from "../../model/data/Location";
import {Equipment} from "../../model/data/Equipment";
import ProtrackJumpFileComponent from "../../ui/component/ProtrackJumpFileComponent";
import {DefaultFabStyle} from "../../ui/component/Fab";
import EditIcon from "@mui/icons-material/Edit";
import FlySightJumpFileComponent from "../../ui/component/FlySightJumpFileComponent";

export default function JumpViewPage() {
    const {t} = useTranslation();
    const {jumpApi, locationApi, equipmentApi} = useApi();
    const [jump, setJump] = useState<Jump | null>(null);
    const [location, setLocation] = useState<Location | null>(null);
    const [equipment, setEquipment] = useState<Equipment | null>(null);
    const [deviceFiles, setDeviceFiles] = useState<Array<DeviceFile>>([]);
    const param = useParams();
    const navigate = useNavigate();

    async function loadData() {
        if (!param.id) {
            throw new Error("Invalid id");
        }
        const jump = (await jumpApi.get(parseInt(param.id)));
        setJump(jump);
        let location: Location | null = null;
        let equipment: Equipment | null = null;
        let deviceFiles: Array<DeviceFile> = [];
        if (jump) {
            deviceFiles = await jumpApi.getJumpFiles(jump);
            if (jump.location) {
                location = await locationApi.get(jump.location);
            }
            if (jump.equipment) {
                equipment = await equipmentApi.get(jump.equipment);
            }
        }
        setDeviceFiles(deviceFiles);
        setLocation(location);
        setEquipment(equipment);
    }

    return (<LoadReloadComponent reload={loadData} >
        <Card variant="outlined">
            <CardContent>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 100 }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>{t('jump.number')}:</TableCell>
                                <TableCell>{jump?.number}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('jump.timestamp')}:</TableCell>
                                <TableCell>{ToolkitTime.dynamicFormatDateTime(jump?.timestamp || null) || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('jump.exitAltitude')}:</TableCell>
                                <TableCell>{jump?.exitAltitude || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('jump.freeFallTime')}:</TableCell>
                                <TableCell>{jump?.freeFallTime || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('jump.equipment')}:</TableCell>
                                <TableCell>{equipment?.name || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('jump.location')}:</TableCell>
                                <TableCell>{location?.name || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t('jump.description')}:</TableCell>
                                <TableCell>{jump?.description || '-'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
        <Fragment>
            {deviceFiles.filter(deviceFile => deviceFile.type === DeviceFileType.PROTRACK_II_DATA)
                .map((deviceFile) => (<ProtrackJumpFileComponent key={deviceFile.id} deviceFile={deviceFile}/>))}
            {deviceFiles.filter(deviceFile => deviceFile.type === DeviceFileType.FLYSIGHT_DATA)
                .map((deviceFile) => (<FlySightJumpFileComponent key={deviceFile.id} deviceFile={deviceFile}/>))}
        </Fragment>
        <Fab onClick={x => navigate('/jump/' + jump?.id)} sx={DefaultFabStyle} color="primary" aria-label="add">
            <EditIcon/>
        </Fab>
    </LoadReloadComponent>);
}
