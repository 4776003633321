import {useEffect, useRef, useState} from "react";
import {GridPaginationModel} from "@mui/x-data-grid/models/gridPaginationProps";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";
import {DataGrid, GridColDef, GridRowSelectionModel} from "@mui/x-data-grid";
import {createCrudDataGrid, CrudApi} from "../../api/CrudApi";
import {GridInitialStateCommunity} from "@mui/x-data-grid/models/gridStateCommunity";
import {Box, Fab, IconButton} from "@mui/material";
import {NavLink, useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {asyncify} from "../../misc/ToolkitAsync";
import DeleteIcon from "@mui/icons-material/Delete";
import {Idable} from "../../model/data/Idable";
import {DefaultFabStyle} from "./Fab";
import AddIcon from "@mui/icons-material/Add";
import {useDialog} from "../../provider/DialogProvider";
import {useTranslation} from "react-i18next";

export default function DataTable<T extends Idable>({api, columns, defaultSort, pageSizeOptions, initialState,
                                                        defaultPageSize, setSelected, editLocation, viewLocation,
                                                        deletable, updater}:
        {api: CrudApi<T>, columns: GridColDef[], defaultSort?: string, pageSizeOptions?: Array<number>,
            initialState?: GridInitialStateCommunity, defaultPageSize?: number, editLocation?: string,
            viewLocation?: string, deletable?: boolean,
            setSelected?: (selected: Array<T>) => void, updater?:number}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {openDialog} = useDialog();
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: defaultPageSize || 20,
    });
    const [gridSortModel, setGridSortModel] = useState<GridSortModel>([{
        field: defaultSort || 'id',
        sort: 'desc'
    }]);

    const {useQuery} = createCrudDataGrid(api);
    const {loadData, rows, isLoading, pageInfo} = useQuery(paginationModel, gridSortModel);

    const [rowCountState, setRowCountState] = useState(
        pageInfo?.totalRowCount || 0,
    );

    function updateSelected(selected: GridRowSelectionModel) {
        if (setSelected) {
            setSelected(rows.filter((row) => selected.includes((row as any).id)));
        }
        setRowSelectionModel(selected);
    }

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            pageInfo?.totalRowCount !== undefined
                ? pageInfo?.totalRowCount
                : prevRowCountState,
        );
    }, [pageInfo?.totalRowCount, setRowCountState]);

    const [useColumns, setUseColumns] = useState<GridColDef[]>(columns);

    const columnsRef = useRef(null);

    function deleteItem(row: T) {
        openDialog(t('dialog.deleteQuestion',
                {item: ((row as any)['name'] ? (row as any)['name'] : t('dialog.deleteQuestionUnnamedItem'))}),
            async () => {
                if (row.id) {
                    await api.delete(row.id);
                }
                await loadData();
            });
    }

    useEffect(asyncify(loadData), [updater]);

    useEffect(() => {
        if (columnsRef.current !== columns) {
            const nextColumns = columns.slice();
            if (editLocation || deletable || viewLocation) {
                nextColumns.push(
                    {
                        field: "Buttons",
                        headerName: "",
                        editable: false,
                        filterable: false,
                        sortable: false,
                        hideable: false,
                        disableReorder: true,
                        disableColumnMenu: true,
                        width: 90,
                        renderCell: (params) => {
                            return (<Box>
                                {editLocation ? <IconButton component={NavLink} to={editLocation + params.row.id}>
                                    <EditIcon/>
                                </IconButton> : ''}
                                {viewLocation ? <IconButton component={NavLink} to={viewLocation + params.row.id}>
                                    <VisibilityIcon/>
                                </IconButton> : ''}
                                {deletable ? (<IconButton onClick={e => deleteItem(params.row)}>
                                    <DeleteIcon/>
                                </IconButton>):''}

                            </Box>);
                        }
                    });
                    }
            setUseColumns(nextColumns);
        }
    }, [columns, editLocation, viewLocation, deletable]);


    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

    return (<Box><DataGrid rows={rows}
                      rowCount={rowCountState}
                      columns={useColumns}
                      loading={isLoading}
                      paginationModel={paginationModel}
                      paginationMode='server'
                      pageSizeOptions={pageSizeOptions || [5, 10, 20, 50, 100]}

                      initialState={initialState}

                      sortingMode='server'
                      sortModel={gridSortModel}
                      onSortModelChange={(model) => {
                          setGridSortModel(model);}}


                      disableColumnFilter

                      checkboxSelection={!!setSelected}

                      onRowSelectionModelChange={updateSelected}
                      rowSelectionModel={rowSelectionModel}

                      keepNonExistentRowsSelected={false}
                      disableRowSelectionOnClick
                           onPaginationModelChange={setPaginationModel}></DataGrid>
        {editLocation ? (
            <Fab onClick={x => navigate(editLocation + 'new')} sx={DefaultFabStyle} color="primary" aria-label="add">
                <AddIcon/>
            </Fab>) : ''}
    </Box>);
}
